import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Root, isDefaultClass } from './MultipleSelect.style';
import { css } from '@emotion/css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    items: any
    label?: string,
    onChange?: any
    placeholder?: string
    value?: any
    variant?: any
    id?: any
}

const MultipleSelect: React.FC<Props> = ({
    items,
    label,
    id,
    variant,
    onChange,
    placeholder,
    value
}) => {
    const handleChange = (event) => {
        const val = event.target.value
        if (val.length >= 1) {
            if (val.findIndex(i => i === 0) > -1) {
                const send = val.splice(1, 1)
                onChange(send)
            } else {
                onChange(val)
            }
        } else {
            onChange(null)
        }
    };

    const typeRootStyle = (variantSend) => {
        switch (variantSend) {
            case 'standard':
                return css`
            fieldset {
                background: linear-gradient(white, white) padding-box,
                             linear-gradient(to right, #370e74, #f10d88) border-box;
                border-radius:10px;
                border: 2px solid transparent;
                transition: all 0.15s;
            }
            input {
                z-index: 1000 !important;
            }
            input::placeholder {
                opacity: 0.3 !important; /* Firefox */
                }
                label {
                    color: black !important;
                }
                `
            case 'outlined':
                return css`
             height: 33px !important;
          
            fieldset {
                background: linear-gradient(white, white) padding-box,
                             linear-gradient(to right, #370e74, #f10d88) border-box;
                border-radius:10px;
                border: 2px solid transparent;
                transition: all 0.15s;
                height: 33px !important;
                margin-top: 14px;
            }
            input {
                height: 33px !important;
            }
            label {
                height: 33px !important;
                font-size: 16px;
                color: #370E74 !important;
                font-family: 'Poppins'
            }
            .MuiSelect-select {
                padding: 0px;
                margin-top: 29px;
            }
            .MuiSelect-icon  {
                z-index: 1;
                > path {
                    fill: #370E74 !important;
                }
            }
                `
            default:
                return typeRootStyle('standard')
                break;
        }
    }

    return (
        <Root className={!value ? isDefaultClass : null }>
            <FormControl fullWidth className={typeRootStyle(variant)}>
                <InputLabel id={id} shrink>{label}</InputLabel>
                <Select
                    placeholder={placeholder}
                    labelId={id}
                    id={id}
                    multiple
                    value={!value ? [0] : value}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                >
                    <MenuItem value={0} disabled>{placeholder}</MenuItem>
                    {items.map(({ id, name }) => (
                        <MenuItem
                            key={id}
                            value={id}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Root>
    );
}
export default MultipleSelect
