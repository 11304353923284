import React from 'react'
import { Root, RootBody, titleMod } from './index.style'
import { Grid, Typography } from '@mui/material'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'

const bannerSrc = require('@/assets/images/terms-banner-prodevent.webp')

const Terms = () => {
  return (
    <Root>
      <HeaderTitle
        title={'Términos y Condiciones'}
        src={bannerSrc}
        className={titleMod}
      />
      <RootBody>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body1' color="#370E74" mb={3}>
              <b>
                ¡Bienvenido a PRODEVENT CHILE! Al unirte a nuestra comunidad, aceptas los siguientes términos y condiciones:
              </b>
            </Typography>
            <FirstText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' mt={3} mb={3}>
              <b>
                Responsabilidades del Portal (PRODEVENT CHILE)
              </b>
            </Typography>
            <SecondText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' mt={3} mb={3}>
              <b>
                Prodevent no se responsabiliza por:
              </b>
            </Typography>
            <LastText />
          </Grid>
        </Grid>
      </RootBody>
    </Root>
  )
}


const FirstText = () => {
  return (
    <Typography variant='body1'>
      <b>
        Privacidad de la Información:</b> La información recopilada de nuestros miembros se utiliza exclusivamente para fines estadísticos y de gestión interna del grupo. Nos comprometemos a proteger la privacidad de tus datos personales de acuerdo con la legislación vigente en Chile.
      <br /><br />
      <b>
        Derecho de Admisión:</b> PRODEVENT CHILE se reserva el derecho de admisión, basándose en criterios establecidos por la organización. Esta decisión es final y no está sujeta a apelaciones.
      <br /><br />
      <b>
        Políticas de uso:</b> Al unirte a PRODEVENT CHILE, te comprometes a utilizar los recursos y servicios de manera responsable y respetuosa con los demás miembros de la comunidad. Cualquier uso inapropiado de la plataforma puede resultar en la suspensión o cancelación de tu membresía.
      <br /><br />
      <b>
        Modificaciones:</b> PRODEVENT CHILE se reserva el derecho de modificar estos términos y condiciones en cualquier momento sin previo aviso. Se recomienda revisar periódicamente esta sección para estar al tanto de cualquier cambio.
      <br /><br />
      <b>
        Propiedad Intelectual:</b> Todo contenido generado por los miembros de PRODEVENT CHILE, como publicaciones, comentarios e imágenes, sigue siendo propiedad de sus respectivos autores. Al publicar en la plataforma, otorgas a PRODEVENT CHILE el derecho no exclusivo de usar, reproducir, modificar, adaptar, publicar, traducir y distribuir dicho contenido para promover la comunidad.
      <br /><br />
      <b>
        Comportamiento y Respeto:</b> Establecemos normas claras de comportamiento y respeto, prohibiendo cualquier forma de acoso, discriminación, insulto o comportamiento inapropiado. Cualquier violación a estas normas puede resultar en la suspensión o expulsión del miembro infractor.
      <br /><br />
      <b>
        Cancelación de Membresía:</b> Para cancelar voluntariamente la membresía o en caso de cancelación por parte de PRODEVENT CHILE, se aplican condiciones específicas. La cancelación puede ser por incumplimiento reiterado de los términos y condiciones o por conducta que perjudique la reputación o el buen funcionamiento de la comunidad.
      <br /><br />
      <b>
        Limitación de Responsabilidad:</b> PRODEVENT CHILE no se hace responsable por daños, pérdidas o lesiones que puedan ocurrir como resultado de la participación en eventos, actividades o el uso de recursos proporcionados por la comunidad.
      <br /><br />
      <b>
        Legislación Aplicable:</b> La legislación chilena es la aplicable en caso de cualquier disputa legal relacionada con los términos y condiciones de PRODEVENT CHILE.
      <br /><br />
      <b>
        Proveedores y Servicios:</b> Las ofertas de proveedores y servicios en el portal de PRODEVENT CHILE son establecidas por los propios comercios y empresas. PRODEVENT CHILE no se hace responsable de negociaciones realizadas fuera del portal. Cualquier acuerdo o transacción fuera de los canales proporcionados por PRODEVENT CHILE es responsabilidad exclusiva de las partes involucradas.
      <br /><br />
      <b>
        Malas Prácticas de Proveedores y Servicios:</b> En PRODEVENT CHILE nos reservamos el derecho de dar por terminados los servicios de cualquier proveedor o servicio que incurra en prácticas consideradas inapropiadas o contrarias a nuestros principios y valores.
      <br /><br />
      <b>
        Compromiso de los Proveedores y Servicios:</b> Los comercios o servicios que publican ofertas en PRODEVENT CHILE se comprometen a respetar el acuerdo establecido en cuanto a días, horarios y ubicaciones. PRODEVENT CHILE actúa únicamente como un canal de publicidad y no realiza transacciones por los servicios seleccionados por los socios.
      <br /><br />
      <b>
        Política de Devolución de Dinero:</b> PRODEVENT CHILE no realizará devoluciones de dinero en ningún caso. Una vez realizado el pago, este no será reembolsable bajo ninguna circunstancia.
      <br /><br />
      <b>
        Diversidad e Inclusión:</b> En Prodevent Chile, celebramos la diversidad en todas sus formas. Valoramos y respetamos a cada miembro de nuestra comunidad, creando un ambiente seguro, acogedor y libre de discriminación. Promovemos la igualdad y la diversidad en nuestros eventos y en nuestra comunidad en general. No discriminamos a nuestros socios por edad, ubicación geográfica, religión, identidad sexual o social. Reconocemos y respetamos las diferencias sociales, raciales y de la comunidad LGBTQ+ en nuestra red, fomentando un ambiente inclusivo para todos los que forman parte de Prodevent Chile.".
      <br /><br />
      <b>
        Reclamos o Evidencia de Mal Comportamiento:</b> En caso de tener un reclamo o evidencia de mal comportamiento relacionado con los servicios ofrecidos en esta plataforma, se debe entrar en contacto con el departamento comercial a través del correo electrónico comercial@prodevent.cl
      <br /><br />
      <b>
        Tiempo Establecido de Asociado:</b> Al ser socio de PRODEVENT CHILE, se establece un período de un año de membresía ya sea como socio productor, estudiante o proveedor a partir de la fecha en que recibas el correo de confirmación de parte de nuestra red. Se enviarán correos de aviso previo para informarte cuando se acerca la fecha de vencimiento de tu membresía. Puedes darte de baja de tu perfil y membresía en cualquier momento enviando un correo a comercial@prodevent.cl.
      <br /><br />
      <b>
        Envío de Correos y Email:</b> Al convertirte en socio, aceptas recibir correos electrónicos de PRODEVENT CHILE, incluyendo publicidad, información importante y otros comunicados relacionados con la comunidad.
      Participación en Eventos y Actividades: Al unirte a PRODEVENT CHILE, aceptas participar en los eventos y actividades organizadas por la comunidad, siempre actuando de manera respetuosa y cumpliendo con las normas establecidas por la organización.
      <br /><br />
      <b>
        Colaboración y Contribución:</b> Se alienta a los miembros a colaborar y contribuir activamente al crecimiento y desarrollo de la comunidad, compartiendo conocimientos, ideas y experiencias para beneficio de todos.
      Aceptación de los Términos y Condiciones: Al unirte a PRODEVENT CHILE, confirmas que has leído, entendido y aceptado todos los términos y condiciones establecidos por la organización, comprometiéndote a cumplir con ellos en todo momento durante tu membresía.
    </Typography>
  )
}

const SecondText = () => {
  return (
    <ol>
      {
        [
          {
            title: 'Gestión de la Comunidad',
            desc: 'Administrar y mantener la plataforma en línea donde los miembros pueden interactuar, compartir información y participar en eventos.'
          },
          {
            title: 'Publicación de Contenido',
            desc: 'Publicar información relevante sobre eventos, actividades, noticias y recursos para los miembros de la comunidad.'
          },
          {
            title: 'Registro de Membresía',
            desc: 'Administrar el registro de nuevos miembros y mantener una base de datos actualizada de los miembros activos.'
          },
          {
            title: 'Comunicación',
            desc: 'Administrar el registro de nuevos miembros y mantener una base de datos actualizada de los miembros activos.'
          },
          {
            title: 'Ofertas y Descuentos',
            desc: 'Negociar y ofrecer ofertas especiales y descuentos en servicios y productos para los miembros de la comunidad.'
          },
          {
            title: 'Resolución de Problemas',
            desc: 'Resolver cualquier problema técnico o de servicio que pueda surgir en la plataforma.'
          },
          {
            title: 'Promoción',
            desc: 'Promover la comunidad y sus actividades para atraer nuevos miembros y aumentar la participación.'
          },
        ].map((i, index) => (
          <li style={{ display: 'flex', marginBottom: 20 }}>
            <Typography variant='body1' mr={3}>{index + 1}.</Typography>
            <Typography variant='body1'>
              <span style={{
                color: '#370E74'
              }} >
                <b>{i.title}:{' '}</b>
              </span>
              {i.desc}
            </Typography>
          </li>
        ))
      }
    </ol>
  )
}

const LastText = () => {
  return (
    <ol>
      {
        [
          {
            title: 'Responsabilidad Legal por Transacciones',
            desc: 'No ser responsable de las transacciones comerciales realizadas entre los miembros y los proveedores externos que ofrecen servicios a través del portal.'
          },
          {
            title: 'Garantía de Servicios de Proveedores',
            desc: 'Siempre será nuestra intención que los proveedores que forman parte de nuestra red sean éticos y responsables. Sin embargo, no podemos garantizar la calidad ni la disponibilidad de los servicios ofrecidos por los proveedores externos. Es responsabilidad de cada proveedor cumplir con los estándares éticos y de calidad esperados por nuestra comunidad. En caso de problemas con un proveedor, nos comprometemos a investigar y tomar medidas adecuadas, pero no podemos asumir responsabilidad directa por sus acciones.'
          },
          {
            title: 'Solución de Disputas entre Miembros',
            desc: 'En PRODEVENT CHILE, nuestra política es no intervenir en disputas entre miembros, a menos que se refieran al uso de la plataforma en sí. Si un socio se siente amenazado por la conducta de otro socio o proveedor, será responsabilidad de cada uno acudir a la policía de su región. Aunque el respeto es fundamental en nuestra red y no nos responsabilizamos por el comportamiento de los socios, evaluaremos cada caso de reclamo o conducta inapropiada que afecte a nuestra comunidad y sus miembros.'
          },
          {
            title: 'Responsabilidad por Contenido Generado por Usuarios en la Plataforma y Redes Sociales',
            desc: 'No asumimos la responsabilidad por el contenido generado por los usuarios, como publicaciones, comentarios o imágenes, tanto en nuestra plataforma como en redes sociales asociadas a Prodevent Chile. No obstante, nos reservamos el derecho a moderar dicho contenido según sea necesario.'
          },
        ].map((i, index) => (
          <li style={{ display: 'flex', marginBottom: 20 }}>
            <Typography variant='body1'>
              <span style={{
                color: '#370E74'
              }} >
                <b>{i.title}:{' '}</b>
              </span>
              {i.desc}
            </Typography>
          </li>
        ))
      }
    </ol>
  )
}

export default Terms