import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const Root = styled.div`
    width: 100%;
    padding-top: 32px;
    .MuiInputLabel-root {
        margin-top: -20px;
        margin-left: -9px;
    }
`

export const isDefaultClass = css`
    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  color: black !important;
  opacity: 0.3 ; 
  z-index: 1;
}
`