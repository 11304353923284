import React from 'react'
import { Root, RootBody } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import List from '@/modules/support/List/List'

const bannerSrc = require('@/assets/images/support-banner-prodevent.webp')
const logoAviSrc = require('@/assets/images/logos/logo_avi_events_prodevent.webp')
const logoRuahSrc = require('@/assets/images/logos/logo_ruah_producciones_prodevent.webp')

const Support = () => {
  return (
    <Root>
      <HeaderTitle
        title={'NOS APOYAN'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h5' mb={4} color="#370E74">
              EMPRESAS QUE NOS APOYAN
            </Typography>
            <Typography variant='body1' mb={-5}>
              En nuestra red de producción de eventos en Chile, contamos con el apoyo de diversas empresas que valoran la calidad y profesionalismo de nuestros miembros. Estas empresas son aliadas clave en nuestro objetivo de fortalecer la industria y brindar oportunidades de crecimiento a nuestros profesionales.
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h5' mb={4} color="#370E74">
              BENEFICIOS DEL APOYO EMPRESARIAL
            </Typography>
            <Typography variant='body1' mb={9}>
              El respaldo de estas empresas va más allá de la contratación de profesionales. Colaboran activamente en la difusión de nuestras actividades, auspician eventos y ofrecen oportunidades laborales a los miembros de nuestra red. Su apoyo integral es esencial para el crecimiento y la consolidación de nuestra comunidad.
            </Typography>
            <Typography variant='h5' mb={4} color="#370E74">
              NUESTRO COMPROMISO MUTUO
            </Typography>
            <Typography variant='body1' mb={-3}>
              Estamos orgullosos de contar con el respaldo de estas empresas, que reconocen el valor de nuestros profesionales y contribuyen significativamente a la profesionalización de la industria de eventos en Chile. Juntos, fortalecemos nuestra carrera colectiva y construimos una comunidad más sólida y profesional.
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h5' mb={4} color="#370E74">
              VENUES - SALONES- HOTELES-Y CENTRO DE EVENTOS
            </Typography>
          </RootBody>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <List />
      </Grid>
    </Root>
  )
}

export default Support