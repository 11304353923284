import { BaseTextFieldProps, FormControl, InputAdornment, InputLabel, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { Root, RootStart } from './InputPhone.style'
import { css } from '@emotion/css'

interface Props extends BaseTextFieldProps {
    label?: string
    placeholder: string
    onChange?: any
}

const InputPhone: React.FC<Props> = ({
    label,
    placeholder,
    variant,
    onChange,
    ...props
}) => {

    const typeRootStyle = (variantSend) => {
        switch (variantSend) {
            case 'standard':
                return css`
                fieldset {
                    transition: all 0.15s;
                    background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box;
                    border-radius:10px;
                    border: 2px solid transparent;
                }
                input {
                    z-index: 1000 !important;
                }
                input::placeholder {
                    opacity: 0.3 !important; /* Firefox */
                    }
                    label {
                        color: black !important;
                    }
                    `
            case 'outlined':
                return css`
                         height: 33px !important;
                     
                    fieldset {
                        margin-top: 14px;
                        padding: 0px 15px;
                        height: 33px !important;
                        background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box;
                    border-radius:10px;
                    border: 2px solid transparent;
                        transition: all 0.15s;
                    }
                    input {
                    z-index: 1000 !important;
                    color: #370e74 !important;
                    }
                    input::placeholder {
                        color: #370e74 !important;
                    }
                    label {
                            color: #370e74 !important;
                        }
                        `
            default:
                return typeRootStyle('standard')
                break;
        }
    }
    return (
        <Root
            style={{ paddingTop: label ? 30 : 0 }}>
            <FormControl variant={variant} className={typeRootStyle(variant)}>
                {label &&
                    <InputLabel shrink>
                        {label}
                    </InputLabel>}
                <TextField
                    onChange={onChange}
                    placeholder={placeholder}
                    inputProps={{
                        maxLength: 9
                    }}
                    {...props}
                    InputProps={{
                        startAdornment: <RootStart>
                            <InputAdornment position="start">+56</InputAdornment>
                        </RootStart>,
                    }}
                />
            </FormControl>
        </Root>
    )
}

export default InputPhone