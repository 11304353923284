import styled from '@emotion/styled';

export const Root = styled.div`
    > div {
        width: 100%;
    }
    .MuiInputLabel-root {
        margin-top: -20px;
        margin-left: -9px;
    }
`
export const SquarePreview = styled.div`
    width: 88px;
    height: 88px;
    border-radius: 14px;
    background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`