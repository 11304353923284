import { maxSizeMobile } from '@/theme';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const Root = styled.div``
export const RootBody = styled.div`
    max-width: 1217px;
    margin:auto;
    padding: 50px 80px;
    @media (max-width: ${maxSizeMobile}) {
        padding: 30px 24px;
    }
`
export const titleMod = css`
    h2 {
        font-size:90px !important;
    }
    @media (max-width: ${maxSizeMobile}) {
        h2 {
            font-size:24px !important;
        }
    }
`