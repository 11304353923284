import { maxSizeMackbook, maxSizeMobile, maxSizeTablet } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
  padding-bottom: 10%;
  background-size: cover;
  padding-left: 52px;
  padding-top: 100px;
  @media (max-width: ${maxSizeMackbook}) {
    padding-bottom: 13%;
  }
  @media (max-width: ${maxSizeTablet}) {
    padding-bottom: 25% !important;
  }
  @media (max-width: ${maxSizeMobile}) {
    padding-bottom: 25% !important;
    padding-left: 25px;
    padding-right: 25px;  padding-top: 40px;
  }
  `
export const RootButton = styled.div`
 margin-top: 30px;
  > div > div   {
    border-radius:20px !important;
  }
  `