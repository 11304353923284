import Axios from 'axios';
import config from '@/config';
import errorLog from '@/utils/errorLog';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (userId): Promise<Response | null> => {
    const path = `/user/${userId}`
    const url = config.API_URL + path
    const headers = { ...config.APIHEADER }
    return Axios.get(url, { headers })
        .then(response => {
            return response.data
        })
        .catch(error => {
            errorLog({ path, url, req: null, error })
            return null
        })
}

interface Response {
    data: any
    error: {
        errorCode: number
        errorMessage: string
    }
}
