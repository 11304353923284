import React from 'react'
import { Root, rootLogoCompany } from './index.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { RootType } from '@/modules/news/Item/Item.style'
import ChevronLeft from '@/components/Icons/ChevronLeft'
import GoBackButton from '@/components/Buttons/GoBackButton/GoBackButton'
import { useNavigate } from 'react-router-dom'
import Card from '@/components/Cards/Card/Card'
import { RootCard } from './index.style'
import Item from '@/modules/courses/Item/Item'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { titleMod } from '../index.style'
import { RootBody } from './index.style'

const bannerSrc = require('@/assets/images/courses-banner-prodevent.webp')
const companyLogoSrc = require('@/assets/images/company-logo-example.webp')

const Course = () => {
    const navigate = useNavigate()
    return (
        <Root>
            <HeaderTitle
                title={'CURSOS Y CAPACITACIONES'}
                src={bannerSrc}
                className={titleMod}
            />
            <RootBody>
                <Grid container>
                    <Grid item xs={12}>
                        <GoBackButton
                            onClick={() => navigate('/courses')}
                            title={'Volver a cursos y capacitaciones'} />
                    </Grid>
                    <Grid item xs={12}>
                        <RootType>
                            <Typography variant='caption' color="white">
                                <b>Capacitación</b></Typography>
                        </RootType>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <Typography variant='h4' style={{ textTransform: 'uppercase' }}>
                            Capacitación </Typography>
                    </Grid>
                    <Grid
                        className={rootLogoCompany}
                        item xs container justifyContent={'center'} alignItems={'center'}>
                        <img src={companyLogoSrc} />
                    </Grid>
                    <Grid item xs={'auto'} container justifyContent={'center'} flexDirection={'column'}>
                        <Typography variant='subtitle2' color='#370E74'>
                            Nombre empresa</Typography>
                        <Typography variant='subtitle2' color='#370E74'>
                            Providencia, Región Metropolitana
                        </Typography>
                        <Typography variant='caption'>
                            Publicada el 2 de feb  de 2024
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Typography variant='body2'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta libero nec justo hendrerit, ac consequat sem ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vel felis eleifend.
                            <br />
                            <br />
                            sodales elit sed, rutrum nunc. Mauris sit amet accumsan justo, nec tincidunt nisi. Nullam nec vehicula arcu. Proin sed enim nec dui efficitur finibus. Pellentesque sit amet varius risus. Integer tristique feugiat ligula, sit amet facilisis libero finibus et. Nunc dapibus tincidunt sapien a viverra. Vivamus vitae neque eget felis tempus ultrices. Vivamus vel accumsan turpis. Suspendisse euismod risus nec magna
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                        <Typography variant='h6' color="#370E74">
                            Requisitos:
                        </Typography>
                        <ul>
                            {
                                [
                                    'sodales elit sed, rutrum nunc. Mauris',
                                    'sit amet accumsan justo, nec tincidunt nisi.',
                                    'Nullam nec vehicula arcu. Proin sed enim',
                                    'nec dui efficitur finibus. Pellentesque sit amet',
                                    'varius risus. Integer tristique feugiat ligula',
                                    'sit amet facilisis libero finibus et.',
                                ].map(i => (
                                    <li>
                                        <Typography variant='body1'>{i}</Typography>
                                    </li>
                                ))
                            }
                        </ul>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                        <Typography variant='h6' color="#370E74">
                            Requerimientos del cargo:
                        </Typography>
                        <ul>
                            {
                                [
                                    'sodales elit sed, rutrum nunc. Mauris sit amet accumsan justo.',
                                    'Nec tincidunt nisi. Nullam nec vehicula arcu. ',
                                    'Proin sed enim nec dui efficitur finibus. ',
                                    'Pellentesque sit amet varius risus. ',
                                    'Integer tristique feugiat ligula, sit amet facilisis libero finibus et. ',
                                    'Nunc dapibus tincidunt sapien a viverra. ',
                                    'Vivamus vitae neque eget felis tempus ultrices. ',
                                    'Vivamus vel accumsan turpis. Suspendisse euismod risus nec magna ',
                                ].map(i => (
                                    <li>
                                        <Typography variant='body1'>{i}</Typography>
                                    </li>
                                ))
                            }
                        </ul>
                    </Grid>
                    <Grid item xs={12} mt={6}>
                        <hr />
                    </Grid>
                    <Grid item xs={12} mt={6}>
                        <Typography variant='h4' style={{ textTransform: 'uppercase' }}>Te podría interesar</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={4} mt={4} mb={4}>
                        {Array.from(Array(3).keys()).map(i => (
                            <Grid item xs={12} md={4} container justifyContent={'center'} alignItems={'center'}>
                                <Item />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </RootBody>
        </Root>
    )
}

export default Course