import { maxSizeMobile } from '@/theme';
import styled from '@emotion/styled';

export const Root = styled.div``
export const RootFilter = styled.div`
    background-color: #370E74;
    max-width: max-content;
    padding: 0px 25px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
        opacity: 0.8;
    }
    :active {
        opacity: 0.5;
    }
    > p {
        user-select: none;
    }
`