import React from 'react'
import { Root, RootButton } from './Banner.style'
import { Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'

const Banner = () => {
  const bannerSrc = require('@/assets/images/prodevent-banner.webp')
  return (
    <Root style={{ backgroundImage: `url(${bannerSrc})` }}>
      <Typography variant='subtitle1' color={'white'}>
        Somos la primera</Typography>
      <Typography variant='h1' color={'white'} style={{ textTransform: 'uppercase', maxWidth: 800, lineHeight: 1.2 }}>
        Red colaborativa de Profesionales
      </Typography>
      <Typography variant='subtitle2' color={'white'} style={{ maxWidth: 900 }}>
        que busca potenciar el impacto y presencia de los productores <b>profesionales de eventos</b> en Chile.
      </Typography>
      <RootButton>
        <Button
          variant='contained'
          style={{ padding: '15px 30px', background: '#FF0082', color: 'white' }}>
          quiero ser parte
        </Button>
      </RootButton>
    </Root>
  )
}

export default Banner