import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './Layout/Layout'
import Login from './pages/login'
import Home from './pages/home'
import Register from './pages/register'
import Contact from './pages/contact'
import Partners from './pages/partners'
import Benefits from './pages/benefits'
import Provider from './pages/provider'
import Jobs from './pages/jobs'
import Courses from './pages/courses'
import Study from './pages/study'
import News from './pages/news'
import Support from './pages/support'
import Job from './pages/jobs/job'
import Course from './pages/courses/course'
import New from './pages/news/new'
import Terms from './pages/terms'
import Policy from './pages/policy'
import Partner from './pages/partners/partner'
import { useAuth } from './provider/AuthProvider'
import token from './api/users/token'
import get from './api/users/get'

const App = () => {
    const [isAuth, setIsAuth] = useState(null)
    const [isVerifyAuth, setIsVerifyAuth] = useState(false)
    
    const auth = useAuth()
  
    useEffect(() => {
        if(!isVerifyAuth) {
            setIsVerifyAuth(true)
            void verifyAuth()
        }
    }, [])
    
    const verifyAuth = async () => {
        const localToken = localStorage.getItem('token')
        if (localToken) {
            const res = await token({ token: localToken })
            if (!res) {
                return console.log('Error al verificar token')
            }
            if (!res.data) {
                setIsAuth(false)
                return console.log('Token Incorrecto')
            }
            setIsAuth(true)
            await handleObtainUser(res.data.id)

        } else {
            setIsAuth(false)
        }

    }

    const handleObtainUser = async (id) => {
        console.log("handleObtainUser",id)
        const resUser = await get(id)
        console.log("resUser",resUser)
        auth.signin(resUser.data, () => { })
    }

    return (
        <>
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='login' element={<Login setIsAuth={setIsAuth} />} />
                    <Route path='register' element={<Register  setIsAuth={setIsAuth} />} />
                    <Route path='contact' element={<Contact />} />
                    <Route path='partners' element={<Partners />} />
                    <Route path='/partners/form' element={<Partner />} />
                    <Route path='benefits' element={<Benefits />} />
                    <Route path='provider' element={<Provider />} />
                    <Route path='jobs' element={<Jobs />} />
                    <Route path='/jobs/job' element={<Job />} />
                    <Route path='courses' element={<Courses />} />
                    <Route path='/courses/course' element={<Course />} />
                    <Route path='study' element={<Study />} />
                    <Route path='news' element={<News />} />
                    <Route path='/news/new' element={<New />} />
                    <Route path='support' element={<Support />} />
                    <Route path='terms' element={<Terms />} />
                    <Route path='policy' element={<Policy />} />
                    <Route path="*" element={<p>No hay nada aquí: 404!</p>} />
                </Route>
            </Routes>
        </>
    )
}

export default App