import React from 'react'
import { Root, RootBody, RootList, titleMod } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import List from '@/modules/courses/List/List'

const bannerSrc = require('@/assets/images/courses-banner-prodevent.webp')

const Courses = () => {
  return (
    <Root>
      <HeaderTitle
        title={'CURSOS Y CAPACITACIONES'}
        src={bannerSrc}
        className={titleMod}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='body1' mb={4}>
              En nuestra red, nos dedicamos a proporcionar herramientas efectivas a través de la creación de comunidades y redes de networking. Ofrecemos una amplia gama de cursos y capacitaciones para promover el crecimiento profesional continuo y preparar a nuestros miembros para los desafíos de nuestra industria.
            </Typography>
            <Typography variant='body1' mb={4}>
              Los socios de <span style={{ color: '#370E74' }}><b>PRODEVENT CHILE</b></span> disfrutan de beneficios especiales en nuestros cursos, con precios preferenciales e incluso acceso gratuito en algunos casos. También organizamos una variedad de eventos relacionados con la profesión y otros temas de interés, a los cuales te invitamos a participar. Consulta nuestro calendario de actividades y regístrate para estar al tanto de todas las oportunidades disponibles.
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <RootList>
            <List />
          </RootList>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Courses