import React from 'react'
import DrawerMUI from '@mui/material/Drawer';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Drawer = ({ open, toggleDrawer }) => {
    const navigate = useNavigate()
    return (
        <div>
            <DrawerMUI
                anchor='right'
                open={open}
                onClose={() => toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={() => toggleDrawer(false)}>
                    <List>
                        {
                            [
                                {
                                    title: 'SOCIOS',
                                    path: '/partners'
                                },
                                {
                                    title: 'BENEFICIOS',
                                    path: '/benefits'
                                },
                                {
                                    title: 'PROVEEDORES',
                                    path: '/provider'
                                },
                                {
                                    title: 'EMPLEOS',
                                    path: '/jobs'
                                },
                                {
                                    title: 'CURSOS Y CAPACITACIONES',
                                    path: '/courses'
                                },
                                {
                                    title: 'DONDE ESTUDIAR',
                                    path: '/study'
                                },
                                {
                                    title: 'NOVEDADES',
                                    path: '/news'
                                },
                                {
                                    title: 'NOS APOYAN',
                                    path: '/support'
                                },
                            ].map(i => (
                                <ListItem>
                                    <ListItemText onClick={() => navigate(i.path)}>
                                        <span>{i.title}</span>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
            </DrawerMUI>
        </div >
    )
}

export default Drawer