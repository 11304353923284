import { Grid, Typography } from '@mui/material'
import React from 'react'
import Carrousel from './Carrousel/Carrousel'

const LastEvent = () => {
  return (
    <div>
        <Grid container>
            <Grid item container mt={10} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant='h3' color="#370E74">Momentos del último evento</Typography>
                <Typography variant='body1' color="#370E74">
                Evento de música realizado en Movistar Arena.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Carrousel />
            </Grid>
        </Grid>
    </div>
  )
}

export default LastEvent