import React from 'react'
import { BannerImg, Root, RootBody } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import List from '@/modules/news/List/List'
import Item from '@/modules/news/Item/Item'
import GoBackButton from '@/components/Buttons/GoBackButton/GoBackButton'
import { useNavigate } from 'react-router-dom'

const bannerSrc = require('@/assets/images/news-banner-prodevent.webp')
const bannerImageSrc = require('@/assets/images/new-banner-image.webp')

const New = () => {
  const navigate = useNavigate()

  return (
    <Root>
      <HeaderTitle
        title={'NOVEDADES'}
        src={bannerSrc}
      />
      <RootBody>
        <Grid container>
          <Grid item xs={12}>
            <RootBody>
              <GoBackButton onClick={() => navigate('/news')} title={'Volver a novedades'} />
              <BannerImg src={bannerImageSrc} />
              <Typography variant='caption'>
                Publicada el 2 de feb  de 2024
              </Typography>
              <Typography variant='h4' mt={2}>
                Lorem ipsum lorem ipsum lorem ipsums
              </Typography>
              <Typography variant='body1' mt={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta libero nec justo hendrerit, ac consequat sem ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vel felis eleifend.
                <br />
                <br />
                sodales elit sed, rutrum nunc. Maris sit amet accumsan justo, nec tincidunt nisi. Nullam nec vehicula arcu. Proin sed enim nec dui efficitur finibus. Pellentesque sit amet varius risus. Integer tristique feugiat ligula, sit amet facilisis libero finibus et. Nunc dapibus tincidunt sapien a viverra. Vivamus vitae neque eget felis tempus ultrices. Vivamus vel accumsan turpis. Suspendisse euismod risus nec magna
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta libero nec justo hendrerit, ac consequat sem ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vel felis eleifend.
                <br />
                <br />
                sodales elit sed, rutrum nunc. Mauris sit amet accumsan justo, nec tincidunt nisi. Nullam nec vehicula arcu. Proin sed enim nec dui efficitur finibus. Pellentesque sit amet varius risus. Integer tristique feugiat ligula, sit amet facilisis libero finibus et. Nunc dapibus tincidunt sapien a viverra. Vivamus vitae neque eget felis tempus ultrices. Vivamus vel accumsan turpis. Suspendisse
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta libero nec justo hendrerit, ac consequat sem ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vel felis eleifend.
                <br />
                <br />
                sodales elit sed, rutrum nunc. Mauris sit amet accumsan justo, nec tincidunt nisi. Nullam nec vehicula arcu. Proin sed enim nec dui efficitur finibus. Pellentesque sit amet varius risus. Integer tristique feugiat ligula, sit amet facilisis libero finibus et. Nunc dapibus tincidunt sapien a viverra. Vivamus vitae neque eget felis tempus ultrices. Vivamus vel accumsan turpis. Suspendisse euismod risus nec magna
              </Typography>
            </RootBody>
          </Grid>
          <Grid item xs={12}>
            <hr />
          </Grid>
          <Grid item xs={12} mt={6}>
            <Typography variant='h4' style={{ textTransform: 'uppercase' }}>Te podría interesar</Typography>
          </Grid>
          <Grid item xs={12} container spacing={3} mt={2}>
            {Array.from(Array(3).keys()).map(i => (
              <Grid item xs={12} md={4} container justifyContent={'center'} alignItems={'center'}>
                <Item />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </RootBody>
    </Root>
  )
}

export default New