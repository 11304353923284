import { createTheme } from "@mui/material";

export const maxSizeTvNum = 2000
export const maxSizeMackbookNum = 1723
export const maxSizeTabletNum = 1200
// export const maxSizeTabletNum = 1024
export const maxSizeMobileNum = 768

export const maxSizeTv = maxSizeMackbookNum + 'px'
export const maxSizeMackbook = maxSizeMackbookNum + 'px'
export const maxSizeTablet = maxSizeTabletNum + 'px'
export const maxSizeMobile = maxSizeMobileNum + 'px'


export const theme = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#FFF',
      dark: '#FFF'
    },
    secondary: {
      main: '#2D2D2D',
      dark: '#2D2D2D'
    },
    background: {
      default: '#FFF'
    },
    warning: {
      main: '#F9CB48'
    }
  }
});

theme.typography.h1 = {
  fontSize: 60,
  fontWeight: 700,
  letterSpacing: -3,
  fontFamily: 'Poppins-ExtraBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 22,
    letterSpacing: -1,
  },
}
theme.typography.h2 = {
  fontSize: 45,
  fontWeight: 700,
  fontFamily: 'Poppins-ExtraBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 38,
  },
}
theme.typography.h3 = {
  fontWeight: 500,
  fontSize: 38,
  lineHeight: 1.15,
  fontFamily: 'Poppins-ExtraBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}
theme.typography.h4 = {
  fontSize: 30,
  fontWeight: 500,
  fontFamily: 'Poppins-ExtraBold',
  color: '#370E74',
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}

theme.typography.h5 = {
  fontSize: 24,
  fontWeight: 'normal',
  fontFamily: 'Poppins-ExtraBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}

theme.typography.h6 = {
  fontSize: 20,
  fontWeight: '600',
  fontFamily: 'Poppins-ExtraBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}


theme.typography.subtitle1 = {
  fontSize: 35,
  fontWeight: 'normal',
  fontFamily: 'Poppins-SemiBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}

theme.typography.subtitle2 = {
  fontSize: 20,
  fontWeight: 'initial',
  fontFamily: 'Poppins-SemiBold',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}

theme.typography.caption = {
  fontSize: 13,
  letterSpacing: 1.2,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    letterSpacing: 1,
  },
}

theme.typography.body1 = {
  fontSize: 18,
  [theme.breakpoints.down('sm')]: {
    fontSize: 15,
  },
}

theme.typography.body2 = {
  fontSize: 16,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}


