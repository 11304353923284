import React from 'react'
import { Root, RootBody, headerMod } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import List from '@/modules/study/List/List'

const bannerSrc = require('@/assets/images/study-banner-prodevent.webp')

const Study = () => {
  return (
    <Root>
      <HeaderTitle
        title={'DÓNDE ESTUDIAR'}
        src={bannerSrc}
        className={headerMod}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h5' mb={4} color="#370E74">
              DÓNDE ESTUDIAR PRODUCCIÓN DE EVENTOS EN CHILE
            </Typography>
            <Typography variant='body1' mb={4}>
              El campo de la producción de eventos, específicamente en la dirección y producción de eventos, ha experimentado un notable crecimiento en Chile. Lo que antes era considerado una carrera alternativa, hoy en día se ha convertido en una de las profesiones más demandadas en la industria del entretenimiento.
              <br/><br/>
              En Chile, varias universidades e instituciones educativas ofrecen programas y cursos especializados en producción de eventos. Además existen numerosos cursos y diplomados cortos que brindan formación específica en este campo. Estos programas son ofrecidos por diversas instituciones y centros educativos.
              <br/><br/>
              La elección de dónde estudiar dependerá de tus intereses y necesidades específicas. Es importante investigar cada opción y considerar aspectos como la duración del programa, el enfoque del plan de estudios, el cuerpo docente y las oportunidades de prácticas profesionales que ofrecen.
              <br/><br/>
              Con una formación adecuada en producción de eventos, podrás acceder a un campo laboral amplio y emocionante, donde tus habilidades y creatividad serán altamente valoradas.
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <List />
        </Grid>
      </Grid>
    </Root>
  )
}

export default Study