import React from 'react'
import { createContext, useContext, useState } from "react";

interface AuthContextType {
    user: any;
    signin: (user: string, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}

let AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [user, setUser] = useState<any>(null);
    
    let signin = (newUser: string, callback: VoidFunction) => {
        setUser(newUser);
        callback();
    };

    let signout = (callback: VoidFunction) => {
        localStorage.removeItem('token')
        setUser(null);
        callback();
    };

    let value = { user, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}