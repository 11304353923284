import React from 'react'
import { Root } from './Logo.style'

const logoSrc = require('@/assets/images/logo.webp')

interface Props {
  maxWidth?: number | string
  className?: any
}

const Logo = ({ maxWidth, ...props }: Props) => {
  return (
    <Root src={logoSrc}
      {...props}
      style={{
        maxWidth: maxWidth || 'inherit'
      }}
    />
  )
}

export default Logo