import Card from '@/components/Cards/Card/Card'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import Form from '@/modules/register/Form'
import FormCompany from '@/modules/register/FormCompany'
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PartyImage, Root, RootCard } from './index.style'
import Tabs from '@/components/Tabs/Tabs'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/provider/AuthProvider'

const bannerSrc = require('@/assets/images/register-banner-prodevent.webp')
const bannerPartySrc = require('@/assets/images/party-register-prodevent.webp')

const names = ['Productor/a', 'Estudiantes', 'Staff de apoyo']

const Register = ({setIsAuth}) => {
  const [value, setValue] = useState('Productor/a')

  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if(auth.user) {
      navigate('/')
    }
  },[auth])

  return (
    <Root>
      <HeaderTitle
        title={'registrar'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item md={6}>
          <PartyImage style={{ backgroundImage: `url(${bannerPartySrc})` }} />
        </Grid>
        <Grid item md={6} container justifyContent={'center'} alignItems={'center'} pl={4} pr={4}>
          <Grid item xs={12}>
            <RootCard>
              <Typography variant='h4' color="#370E74" mb={5}>BIENVENIDO(A)</Typography>
              {/* <Tabs names={names}
                setValue={setValue}
                value={value}
              /> */}
              <Card title={'Iniciar sesión'} paddingInterior={'50px 20px'}>
                <Form setIsAuth={setIsAuth}  />
              </Card>
            </RootCard>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Register