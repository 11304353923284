import React from 'react'
import { Root, RootBody, titleMod } from './index.style'
import { Grid, Typography } from '@mui/material'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'

const bannerSrc = require('@/assets/images/terms-banner-prodevent.webp')

const Policy = () => {
  return (
    <Root>
      <HeaderTitle
        title={'Política de privacidad'}
        src={bannerSrc}
        className={titleMod}
      />
      <RootBody>
        <Grid container>
          <Grid item xs={12}>
            <FirstText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' mt={3} mb={3}>
              <b>
                RECOPILACIÓN DE DATOS
              </b>
            </Typography>
            <SecondText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' mt={3} mb={3}>
              <b>
                Misión
              </b>
            </Typography>
            <LastText />
          </Grid>
        </Grid>
      </RootBody>
    </Root>
  )
}


const FirstText = () => {
  return (
    <Typography variant='body1'>
      En Prodevent, valoramos su privacidad y nos esforzamos por facilitar la conexión entre productores y diversos proveedores con objetivos comunes. Nuestro compromiso radica en la transparencia en la recopilación y uso de los datos generados por nuestros usuarios, así como en su compartición con terceros.
      <br /><br />
      Nuestros miembros comparten sus perfiles profesionales, conectan entre sí, intercambian información y experiencias, publican opiniones y adquieren conocimientos. Algunas secciones de Prodevent también están abiertas al público en general.
    </Typography>
  )
}

const SecondText = () => {
  return (
    <ol>
      {
        [
          {
            title: 'A través de formularios de inscripción',
            desc: 'Para diferenciar entre cuentas premium y gratuitas, solicitamos información básica como nombre, correo electrónico, teléfono y contraseña.'
          },
          {
            title: 'Perfil',
            desc: 'Recopilamos datos personales como tu grado académico, ubicación, experiencia laboral y nombre completo. Estos datos facilitan la conexión entre productores y proveedores, así como la identificación de oportunidades de negocios. Recuerda no publicar información sensible.'
          },
          {
            title: 'Noticias, Novedades y Premiaciones',
            desc: 'Es posible que publiquemos información sobre ti en nuestra sección de noticias y premiaciones, donde reportamos eventos y novedades de proveedores con nuevos productos y tecnologías.'
          },
          {
            title: 'Comunidad en WhatsApp Web',
            desc: 'Los mensajes se enviarán por defecto a tu teléfono personal (número privado), por lo que tu número se integrará a nuestra comunidad.'
          },
          {
            title: 'Proveedores',
            desc: 'Recibimos datos de empresas asociadas que utilizan nuestros servicios. También recibimos y enviamos contactos directamente de nuestros socios a través de diversas plataformas para mejorar nuestra red.'
          },
        ].map((i, index) => (
          <li style={{ display: 'flex', marginBottom: 20 }}>
            <Typography variant='body1'>
              <span style={{
                color: '#370E74'
              }} >
                <b>{i.title}:{' '}</b>
              </span>
              {i.desc}
            </Typography>
          </li>
        ))
      }
    </ol>
  )
}

const LastText = () => {
  return (
    <ol>
      {
        [
          {
            title: 'La misión de Prodevent es',
            desc: 'facilitar la conexión entre productores y proveedores para agilizar cotizaciones y logística entre regiones, así como encontrar oportunidades laborales y fomentar el intercambio de ideas y experiencias profesionales. Contribuir a la limpieza del mercado es uno de nuestros objetivos.'
          },
          {
            title: 'Fortalecimiento del Socio Proveedor en el Mercado',
            desc: 'Buscamos descentralizar y fortalecer al socio proveedor en el mercado para mejorar la eficiencia y competitividad.'
          },
          {
            title: 'Uso de Cookies',
            desc: 'Las cookies son herramientas útiles que permiten personalizar la experiencia del usuario al recordar sus preferencias, como el idioma seleccionado o la configuración de la página, lo que facilita la navegación. Algunas cookies son esenciales para el funcionamiento del sitio web, como aquellas que permiten a los usuarios iniciar sesión en sus cuentas o realizar compras en línea. En nuestra plataforma, utilizamos cookies y otras tecnologías de seguimiento, y ofrecemos a los usuarios la opción de aceptar o rechazar su uso.'
          },
          {
            title: 'Seguridad de los Datos',
            desc: 'Tenemos medidas de seguridad implementadas para proteger los datos de los usuarios, como el cifrado de datos y las medidas de protección contra accesos no autorizados.'
          },
          {
            title: 'Retención de Datos',
            desc: 'Conservamos los datos de los usuarios durante el tiempo requerido para cumplir con los fines para los que se recopilaron. Cuando los datos ya no sean necesarios para estos fines, se eliminarán de manera segura. Si deseas gestionar la eliminación de tus datos, puedes enviar un correo a comercial@prodevent.cl solicitando la gestión correspondiente.'
          },
          {
            title: 'Transferencia de Datos',
            desc: 'Los datos de los usuarios pueden ser transferidos a otros países con el fin de beneficiar a nuestros socios o para fines estadísticos. Nos comprometemos a garantizar que no se haga un mal uso de esta información.'
          },
          {
            title: 'Contacto',
            desc: 'Para cualquier pregunta o inquietud sobre la privacidad de tus datos, proporcionamos el correo electrónico comercial@prodevent.cl como medio de contacto. Estamos aquí para ayudarte y garantizar la seguridad de tus datos en nuestra plataforma.'
          },
        ].map((i, index) => (
          <li style={{ display: 'flex', marginBottom: 20 }}>
            <Typography variant='body1'>
              <span style={{
                color: '#370E74'
              }} >
                <b>{i.title}:{' '}</b>
              </span>
              {i.desc}
            </Typography>
          </li>
        ))
      }
    </ol>
  )
}

export default Policy