import React, { useEffect, useState } from 'react'
import { Root } from './index.style'
import { Checkbox, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Input from '@/components/Input/Input'
import Select from '@/components/Select/Select'
import InputFile from '@/components/Input/InputFile/InputFile'
import { Button } from '@/components/Buttons/Button'
import getComunas from '@/api/comuna/get'
import getRegion from '@/api/region/get'
import getLanguage from '@/api/language/get'
import MultipleSelect from '@/components/Select/MultipleSelect/MultipleSelect'
import InputPhone from '@/components/Input/InputPhone/InputPhone'
import create from '@/api/userForm/create'
import { Fn } from '@/utils/validations'

const ProductorForm = ({
    formValue
}) => {
    const [listComuna, setListComuna] = useState([])
    const [listRegion, setListRegion] = useState([])
    const [listLanguage, setListLanguage] = useState([])
    const [name, setName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [rut, setRut] = useState(null)
    const [nation, setNation] = useState(null)
    const [address, setAddress] = useState(null)
    const [comuna, setComuna] = useState(null)
    const [region, setRegion] = useState(null)
    const [language, setLanguage] = useState(null)
    const [phone, setPhone] = useState(null)
    const [gender, setGender] = useState(null)
    const [profession, setProfession] = useState(null)
    const [typeEvent, setTypeEvent] = useState([])
    const [error, setError] = useState(null)

    const obtainComunas = async () => {
        const res = await getComunas()
        if (!res) {
            return console.log("Error al obtener comunas")
        }
        setListComuna(res.data)
    }

    const obtainRegion = async () => {
        const res = await getRegion()
        console.log('first', res)
        if (!res) {
            return console.log("Error al obtener regiones")
        }
        setListRegion(res.data)
    }

    const obtainLanguage = async () => {
        const res = await getLanguage()
        if (!res) {
            return console.log("Error al obtener idiomas")
        }
        setListLanguage(res.data)
    }

    useEffect(() => {
        void obtainComunas()
        void obtainRegion()
        void obtainLanguage()
    }, [])

    const onSubmit = () => {
        console.log("comuna", comuna)
        setError(null)
        if (!name) {
            return setError('Te falta ingresar un nombre')
        }
        if (!lastName) {
            return setError('Te falta ingresar un apellido')
        }
        if (!rut) {
            return setError('Te falta ingresar un rut')
        }
        if (!Fn.validaRut(rut)) {
            return setError('El formato del rut es incorrecto')
        }
        if (!nation) {
            return setError('Te falta ingresar una nacionalidad')
        }
        if (!address) {
            return setError('Te falta ingresar una dirección')
        }
        if (!comuna || comuna === 0) {
            return setError('Te falta ingresar una comuna')
        }
        if (!region || region === 0) {
            return setError('Te falta ingresar una región')
        }
        if (!language || language === 0) {
            return setError('Te falta ingresar los idiomas')
        }
        if (!phone) {
            return setError('Te falta ingresar un teléfono')
        }
        if (!Fn.validatePhone(phone) || phone.length < 9) {
            return setError('El formato de tu teléfono es incorrecto')
        }
        if (!gender) {
            return setError('Te falta ingresar un género')
        }
        if (!profession) {
            return setError('Te falta ingresar una profesión')
        }
    }

    const createUserForm = async () => {
        const res = await create({
            name,
            lastName,
            rut,
            nation,
            address,
            comuna,
            region,
            language,
            phone,
            gender,
            profession
        })
    }

    return (
        <Root>
            <Grid container item spacing={4}>
                <Grid item xs={12} md={12}>
                    <Typography variant='h4'>
                        {formValue === 1 && 'FORMULARIO DE PRODUCTOR PROFESIONAL'}
                        {formValue === 2 && 'FORMULARIO DE PRODUCTORES CERTIFICADO'}
                        {formValue === 3 && 'FORMULARIO DE ESTUDIANTES DE PRODUCCIÓN DE EVENTOS'}
                        {formValue === 4 && 'FORMULARIO DE STAFF DE APOYO'}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Nombre'
                        placeholder='Ingresa tu nombre'
                        value={name}
                        onChange={(e) => setName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Apellido'
                        placeholder='Ingresa tu apellido'
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Rut'
                        placeholder='xx.xxx.xxx-x'
                        value={rut}
                        onChange={(e) => setRut(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Nacionalidad'
                        placeholder='Ingresa tu nacionalidad'
                        value={nation}
                        onChange={(e) => setNation(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Input
                        label='Dirección'
                        placeholder='Ingresa aquí tu dirección'
                        value={address}
                        onChange={(e) => setAddress(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select
                        label={'Región'}
                        placeholder='Selecciona tu región'
                        items={listRegion}
                        variant='filled'
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select
                        label={'Comuna'}
                        placeholder='Selecciona tu comuna'
                        items={listComuna}
                        variant='filled'
                        value={comuna}
                        onChange={(e) => setComuna(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MultipleSelect
                        label={'Idiomas que domina'}
                        placeholder='Selecciona los idiomas'
                        items={listLanguage}
                        variant='filled'
                        value={language}
                        onChange={setLanguage}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputPhone
                        label='Teléfono'
                        placeholder='Ingresa aquí tu teléfono'
                        value={phone}
                        onChange={(e) => setPhone(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select
                        label={'Género'}
                        placeholder={'Selecciona tu género'}
                        items={[
                            { id: 'M', name: 'Masculino' },
                            { id: 'F', name: 'Femenino' },
                            { id: 'O', name: 'Otro' },
                        ]}
                        variant='filled'
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Profesión'
                        placeholder='Ingresa aquí tu profesión'
                        value={profession}
                        onChange={(e) => setProfession(e.currentTarget.value)}
                    />
                </Grid>
                {formValue === 2 &&
                    <Grid item xs={12} md={6}>
                        <Select
                            label='Años de Experiencia Produciendo Eventos'
                            placeholder='Selecciona tu experiencia'
                            items={
                                Array.from(Array(50).keys()).map(i => ({
                                    id: i + 1, name: i + 1
                                }))
                            }
                            variant='filled'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        />
                    </Grid>}
                <Grid item xs={12}>
                    {formValue !== 3 &&
                        <Typography variant='h6' color="#370E74">
                            Marca el tipo de evento que realizas
                        </Typography>
                    }
                </Grid>
                {formValue !== 4 && formValue !== 3 &&
                    <Grid container item xs={12}>
                        <MultipleCheckbox
                            md={6}
                            value={typeEvent}
                            onChange={setTypeEvent}
                            items={[
                                'CORPORATIVO',
                                'AGENCIA',
                                'SOCIALES',
                                'MICE',
                                'DEPORTIVO',
                                'CULTURALES',
                                'CONCIERTOS',
                                'OTROS',
                            ]}
                        />
                    </Grid>}
                {formValue === 4 &&
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <MultipleCheckbox
                                md={12}
                                value={typeEvent}
                                onChange={setTypeEvent}
                                items={[
                                    'STAFF DE APOYO ',
                                    'TÉCNICOS'
                                ]}
                            />
                        </Grid>
                    </Grid>
                }
                {formValue !== 4 &&
                    <Grid item xs={6}>
                        <InputFile
                            title={'Foto tipo carnet'}
                            desc={'Sube aquí la foto de tu carnet de identidad'}
                        />
                    </Grid>}
                {formValue !== 2 && formValue !== 3 &&
                    <Grid item xs={6}>
                        <InputFile
                            title={'Título de estudio'}
                            desc={'Sube aquí la foto de tu título de estudio'}
                        />
                    </Grid>}
                {formValue === 3 &&
                    <Grid item xs={6}>
                        <InputFile
                            title={'Cartas de Recomendaciones'}
                            desc={'Sube tus Cartas de Recomendaciones'}
                        />
                    </Grid>}
                {formValue === 3 && formValue !== 4 &&
                    <Grid item xs={6}>
                        <InputFile
                            title={'Carta de Alumno Regular'}
                            desc={'Sube tu Carta de Alumno Regular'}
                        />
                    </Grid>}
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Button
                        onClick={onSubmit}
                        variant='contained' style={{
                            maxWidth: 900,
                            width: '100%',
                            height: 60
                        }}>CREAR CUENTA</Button>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    {error && <Typography variant='body1' style={{ color: 'red' }}>{error}</Typography>}
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Typography variant='body1'>¿Ya tienes cuenta? <Link to={'/login'}><b>Iniciar sesión aquí</b></Link></Typography>
                </Grid>
            </Grid>

        </Root>
    )
}

const MultipleCheckbox = ({
    items,
    value,
    onChange,
    md
}) => {

    const onChangeToggle = (name) => {
        const finder = value.findIndex(i => i === name)
        if (finder > -1) {
            let val = Object.assign([], value)
            val.splice(finder, 1)
            onChange(val)
        } else {
            let val = Object.assign([], value)
            val.push(name)
            onChange(val)
        }
    }

    return items.map(i => (
        <Grid item xs={12} md={md} container justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant='subtitle2'>
                {i}
            </Typography>
            <Checkbox
                onChange={() => onChangeToggle(i)}
            />
        </Grid>
    ))
}

export default ProductorForm