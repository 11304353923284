import React from 'react'
import { ListIcon, ListIconContact, Root, RootBottom, SocialImg } from './Footer.style'
import { Grid, Typography } from '@mui/material'
import Logo from '@/components/Logo/Logo'
import { Link } from 'react-router-dom'

const instaSrc = require('@/assets/images/rrss/instagram.webp')
const faceSrc = require('@/assets/images/rrss/facebook.webp')
const linkSrc = require('@/assets/images/rrss/linkedin.webp')
const twitterSrc = require('@/assets/images/rrss/twitter.webp')

const Footer = () => {
    return (
        <Root>
            <Grid container spacing={5}>
                <Grid item md={6}>
                    {/* <Logo maxWidth={'100%'} /> */}
                    <Typography variant='h5' color={'white'}>Información</Typography>
                    <div>
                        <Typography variant='body2' mt={3} color={'white'}>
                            Estamos disponibles de lunes a viernes, de 9:00 a 18:00 hrs. Contáctanos por nuestros canales oficiales de redes sociales o de nuestra página web.
                        </Typography>
                    </div>
                    <Grid container spacing={2} mt={2}>
                        {
                            [
                                {
                                    src: linkSrc,
                                    url: 'https://www.linkedin.com/company/prodeventchile-red-chilena-de-productores-profesionales-de-eventos/about/?viewAsMember=true'
                                },
                                {
                                    src: instaSrc,
                                    url: 'https://instagram.com/PRODEVENT.CHILE'
                                },
                                {
                                    src: faceSrc,
                                    url: 'https://www.facebook.com/profile.php?id=100067510161915'
                                }
                            ].map(i => (
                                <Grid item>
                                    <a href={i.url} target='_blank'>
                                        <SocialImg src={i.src} />
                                    </a>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item md={3} container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h5' color={'white'}><b>Menu</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ListIcon>
                            {
                                [
                                    {
                                        title: 'Socios',
                                        path: '/partners',
                                    },
                                    {
                                        title: 'Beneficios',
                                        path: '/benefits',
                                    },
                                    {
                                        title: 'Proveedores ',
                                        path: '/provider',
                                    },
                                    {
                                        title: 'Empleos',
                                        path: '/jobs',
                                    },
                                    {
                                        title: 'Cursos y capacitaciones',
                                        path: '/courses',
                                    },
                                    {
                                        title: 'Dónde estudiar',
                                        path: '/study',
                                    },
                                    {
                                        title: 'Novedades',
                                        path: '/news',
                                    },
                                    {
                                        title: 'Nos apoyan',
                                        path: '/support',
                                    },
                                    {
                                        title: 'Términos y condiciones',
                                        path: '/terms',
                                    },
                                    {
                                        title: 'Políticas de privacidad',
                                        path: '/policy',
                                    },
                                ].map(i => (
                                    <li>
                                        <Link to={i.path}>
                                            <Typography variant='body2' color={'white'}>{i.title}</Typography>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ListIcon>
                    </Grid>
                </Grid>
                <Grid item md={3}>
                    <div>
                        <Typography variant='h5' color={'white'}><b>Contacto</b></Typography>
                    </div>
                    <ListIconContact>
                        <li>
                            <Typography variant='body2' color={'white'}>Providencia , Santiago de Chile</Typography>
                        </li>
                        <li>
                            <a href="mailto:info@prodevent.cl">
                            <Typography variant='body2' color={'white'}>info@prodevent.cl</Typography>
                            </a>
                        </li>
                    </ListIconContact>
                </Grid>
                <Grid item xs={12}>
                    <RootBottom>
                        <Typography variant='body2' color={'white'}>
                            TODOS LOS DERECHOS RESERVADOS | PRODEVENT © 2024
                        </Typography>
                    </RootBottom>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Footer