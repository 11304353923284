import React from 'react'
import { Root } from './List.style'
import { Grid, Typography } from '@mui/material'
import Card from '@/components/Cards/Card/Card'
import Item from '../Item/Item'

const List = () => {
    return (
        <Root>
            <Card paddingInterior={40}>
                <Grid container>
                    <Grid item xs={12} container justifyContent={'center'} alignItems={'center'}>
                        <Typography variant='h4' mb={4}>
                            LO ÚLTIMO</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={7}>
                        {Array.from(Array(8).keys()).map(i => (
                            <Grid item xs={12} md={6} container justifyContent={'center'} alignItems={'center'}>
                                <Item />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Card>
        </Root>
    )
}

export default List