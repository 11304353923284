import React from 'react'
import { Root, RootBody } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import List from '@/modules/news/List/List'

const bannerSrc = require('@/assets/images/news-banner-prodevent.webp')

const News = () => {
  return (
    <Root>
      <HeaderTitle
        title={'NOVEDADES'}
        src={bannerSrc}
      />
      <Grid container mt={7}>
        <Grid item xs={12} mb={5}>
          <List />
        </Grid>
      </Grid>
    </Root>
  )
}

export default News