import { maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div``

export const RootCard = styled.div`
    max-width: 550px;
    margin:auto;
    @media (max-width: ${maxSizeMobile}) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

export const PartyImage = styled.div`
    height: 0px;
    padding-bottom: 150%;
    background-size: cover;
`
