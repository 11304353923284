import React from 'react'
import { FirstCard, Root, SecondCard } from './BannerCards.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'

const BannerCards = () => {
  return (
    <Root>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FirstCard>
            <Typography variant='h5' color={'white'} mb={2}>
              ¿Qué hacemos?
            </Typography>
            <Typography variant='body1' color={'white'}>
              La Red Chilena de Productores Profesionales de Eventos es una comunidad dedicada a promover la excelencia y profesionalización en la industria de eventos en Chile.
            </Typography>
          </FirstCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <SecondCard>        
            <Typography variant='h5' color={'white'} mb={2}>
            Red Nacional
            </Typography>
            <Typography variant='body1' color={'white'}>
            Nuestra red está compuesta por productores, organizadores y profesionales del sector que buscan colaborar, aprender y crecer juntos en un ambiente de apoyo y camaradería.
            </Typography>
          </SecondCard>
        </Grid>
      </Grid>
    </Root>
  )
}

export default BannerCards