export const Fn = {
  validatePhone: (phone: string) => /^\d+$/.test(phone),
  validateEmail: (mail: string) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail),
  hiddenEmail: (mail: string) => {
    const mailFirst = mail.split('@')[0]
    const mailSecond = mail.split('@')[1]
    if(mailFirst.length <= 1 ) {
      return mail
    }
    const qty = Math.floor(mailFirst.length / 2)
    const hideNum = []
    for (let i = 0; i < mailFirst.length; i++) {
      if (i < mailFirst.length - qty) {
        hideNum.push('*')
      } else {
        hideNum.push(mailFirst[i])
      }
    }
    return hideNum.join('') + '@' + mailSecond
  },
  hiddenPhone: (phone: string) => {
    const hideNum = []
    for (let i = 0; i < phone.length; i++) {
      if (i < phone.length - 4) {
        hideNum.push('*')
      } else {
        hideNum.push(phone[i])
      }
    }
    return hideNum.join('')
  },
  validaRut: function (rutCompleto: string) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
      return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (Fn.dv(rut) == digv);
  },
  dv: function (T:any) {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';
  }
}