import { maxSizeMobile, theme } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
    border-radius: 33px;
    position: relative;
    box-shadow: 0px 4px 9px rgba(0,0,0,0.4);
`

export const RootContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
`
export const RootTitle = styled.div`
    position: absolute;
    top: -30px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: ${maxSizeMobile}) {
        top: -20px;
        left: 20px;
        > h3 {
            font-size:26px;
        }
    }
`
export const RootRight = styled.div`
    position: absolute;
    top: -50px;
    right: 50px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;

`
