import React, { useEffect, useState } from 'react'
import { Root, RootBody, RootCard, headerMod, rootBg } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Card, Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { useNavigate } from 'react-router-dom'
import Forms from '@/modules/partners/Forms'
import { scrollToId } from '@/utils/scrollToId'

const bannerSrc = require('@/assets/images/partners-banner-prodevent.webp')

const Partners = () => {
  const [formValue, setFormValue] = useState(null)

  useEffect(() => {
    if (formValue !== null) {
      scrollToId('partners-forms-top')
      if (formValue === 0) {
        scrollToId('partners-home-top')
    }
    }
  }, [formValue])

  return (
    <Root>
      <HeaderTitle
        title={'ZONA DE SOCIOS'}
        src={bannerSrc}
        className={headerMod}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h4' mb={4}>
              ¿POR QUÉ SER PARTE DE PRODEVENT CHILE?
            </Typography>
            <Typography variant='body1' mb={4}>
              Al unirte a nuestra red, obtendrás oportunidades de networking y tutoría con profesionales de la industria, así como la posibilidad de establecer contactos valiosos para tu futuro en el campo de eventos en todos sus ámbitos.
              <br />
              <br />
              Nuestra comunidad es un excelente espacio para generar experiencias profesionales, lo que la hace atractiva tanto para posibles empleadores como para candidatos en búsqueda de empleo.
              <br />
              <br />
              Tendrás acceso a seminarios de capacitación y educación tanto presenciales como virtuales, podrás asistir a eventos del gremio, conocer nuevos proveedores y mantenerte al día con las últimas tecnologías que apoyan el desarrollo de eventos exitosos.
            </Typography>
            <Typography variant='h4' mb={4}>
              ¿CÓMO ASOCIARSE A LA RED NACIONAL DE PRODUCTORES PROFESIONALES DE EVENTOS?
            </Typography>
            <Typography variant='h4' mb={4}>
              PARA SER PARTE ES MUY FÁCIL
            </Typography>
          </RootBody>
        </Grid>
        <Grid container item xs={12} className={rootBg} spacing={9} mt={4}>
          {
            !formValue
              ? <StepByStep
                setFormValue={setFormValue}
              />
              : <Forms
                formValue={formValue}
                setFormValue={setFormValue}
              />
          }
        </Grid>
      </Grid>
    </Root>
  )
}

const StepByStep = ({
  setFormValue
}) => {
  return (
    <>
      <Grid item xs={12} id={'partners-home-top'}>
        <Typography variant='h4' color={'white'}>
          PASO A PASO DE LA INSCRIPCIÓN
        </Typography>
        <ol>
          {
            [
              'Completa el formulario de inscripción, seleccionando la categoría que te identifica: productor profesional, productor certificado, estudiante o staff de apoyo.',
              'Asegúrate de escribir correctamente tus datos personales y adjuntar los documentos requeridos.',
              'Después de enviar el formulario, recibirás un correo de respuesta con la confirmación de tu aceptación.',
              'Una vez aceptado, sigue las instrucciones proporcionadas para realizar el pago de membresía.',
              'Una vez que hayamos verificado tu pago, tu perfil será activado y podrás acceder a la plataforma con tu usuario y contraseña.',
            ].map(i => (
              <li>
                <Typography variant='body1' color={'white'}>{i}</Typography>
              </li>
            ))
          }
        </ol>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={20}>
          <RootCard>
            <Typography variant='h5' color="#370E74" mb={2}>1. PRODUCTORES/AS PROFESIONALES DE EVENTOS</Typography>
            <Typography variant='body1' mb={3}>
              Si eres un/a Productor/a Profesional de eventos con titulación en Producción, Gestión u Organización de eventos de una institución reconocida por el Ministerio de Educación de Chile, puedes asociarte. Debes contar con título profesional.
            </Typography>
            <Typography variant='h6' color="#370E74">
              Precio de lanzamiento anual para Productores/as Profesionales de Eventos
            </Typography>
            <Typography variant='h5' color="#370E74" mb={4}>
              $30.000
            </Typography>
            <Typography variant='h6' color="#370E74">
              Incluye
            </Typography>
            <ul>
              {
                [
                  'Credencial de identificación digital para acceder a descuentos especiales de proveedores en todo Chile',
                  'Acceso a descuentos y beneficios personales con comercios y servicios como gimnasios, contadores, psicólogos, cafeterías, entre otros',
                  'Acceso gratuito a actividades de la comunidad',
                  'Acceso a postular a Empleos exclusivos para socios PRODEVENT',
                  'Descuentos especiales en cursos dictados especialmente para los productores socios'
                ].map(i => (
                  <li>
                    {i}
                  </li>
                ))
              }
            </ul>
            <Grid container item justifyContent={'flex-end'}>
              <Button variant='contained' onClick={() => setFormValue(1)}>
                Inscríbete aquí</Button>
            </Grid>
          </RootCard>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={20}>
          <RootCard>
            <Typography variant='h5' color="#370E74" mb={2}>2. PRODUCTORES /AS DE EVENTOS CERTIFICADO</Typography>
            <Typography variant='body1' mb={3}>
              Si te dedicas a la producción de eventos como tu ocupación principal, a pesar de haber estudiado otra carrera. Deberás presentar tres cartas de recomendación o cartas de trabajo que detallen fechas, nombre de la empresa, jefe a cargo y datos de contacto de las referencias, o bien, nombres y contactos de clientes. Debes tener más de 5 años de experiencia en la producción de eventos.
            </Typography>
            <Typography variant='h6' color="#370E74">
              Precio de lanzamiento anual para Productores/as de eventos certifciado
            </Typography>
            <Typography variant='h5' color="#370E74" mb={4}>
              $30.000
            </Typography>
            <Typography variant='h6' color="#370E74">
              Incluye
            </Typography>
            <ul>
              {
                [
                  '1 Credencial de identificación digital para acceder a descuentos especiales de proveedores en todo Chile',
                  'Acceso a descuentos y beneficios personales con comercios y servicios como gimnasios, contadores, psicólogos, cafeterías, entre otros',
                  'Acceso gratuito a actividades de la comunidad',
                  'Acceso a postular a licitaciones especiales',
                  'Acceso a postular a Empleos exclusivos para socios PRODEVENT',
                  'Descuentos especiales en cursos dictados especialmente para los productores socios',
                ].map(i => (
                  <li>
                    {i}
                  </li>
                ))
              }
            </ul>
            <Grid container item justifyContent={'flex-end'} mt={3}>
              <Button variant='contained' onClick={() => setFormValue(2)}>
                Inscríbete aquí</Button>
            </Grid>
          </RootCard>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={20}>
          <RootCard>
            <Typography variant='h5' color="#370E74" mb={2}>3. ESTUDIANTES DE  PRODUCCIÓN DE EVENTOS</Typography>
            <Typography variant='body1' mb={3}>
              Si estás cursando estudios en Producción, Gestión u Organización de eventos en una institución reconocida por el Ministerio de Educación de Chile, puedes asociarte a nuestra red. Para ello, necesitarás contar con un certificado de alumno regular.
            </Typography>
            <Typography variant='h6' color="#370E74">
              Precio de lanzamiento anual para Estudiantes de Producción de eventos
            </Typography>
            <Typography variant='h5' color="#370E74" mb={4}>
              $15.000
            </Typography>
            <Typography variant='h6' color="#370E74">
              Incluye
            </Typography>
            <ul>
              {
                [
                  '1 Credencial de identificación digital para acceder a descuentos especiales para la comunidad',
                  'Acceso a descuentos y beneficios personales con comercios y servicios como gimnasios, contadores, psicólogos, cafeterías, entre otros',
                  'Acceso gratuito a actividades de la comunidad',
                  'Acceso a postular a Practicas exclusivas para socios PRODEVENT',
                  'Descuentos especiales en cursos dictados especialmente para los estudiantes socios'
                ].map(i => (
                  <li>
                    {i}
                  </li>
                ))
              }
            </ul>
            <Typography variant='body1' mt={7}>¡Y esperamos agregar cada vez más beneficios!</Typography>
            <Grid container item justifyContent={'flex-end'} mt={3}>
              <Button variant='contained' onClick={() => setFormValue(3)}>
                Inscríbete aquí</Button>
            </Grid>
          </RootCard>
        </Card>
      </Grid>
      <Grid item xs={12} mb={3}>
        <Card elevation={20}>
          <RootCard>
            <Typography variant='h5' color="#370E74" mb={2}>4. STAFF DE APOYO</Typography>
            <Typography variant='body1' mb={3}>
              Si cuentas con experiencia como personal de apoyo en eventos, ya sea como anfitrión/a, runner, montajista, iluminador, técnico, entre otros, Si cuentas con experiencia como personal de apoyo en eventos, ya sea como anfitrión/a, runner, montajista, iluminador, técnico, entre otros,
              <br />
              <br />
              ¡Inscríbete gratuitamente!
            </Typography>
            <Typography variant='h6' color="#370E74" mb={2}>
              Promoción de lanzamiento
            </Typography>
            <Typography variant='h6' color="#370E74">
              Incluye
            </Typography>
            <Typography variant='body1'>Acceso al portal de empleos con vacantes de apoyo para eventos en todo Chile.</Typography>
            <Grid container item justifyContent={'flex-end'} mt={3}>
              <Button variant='contained' onClick={() => setFormValue(4)}>
                Inscríbete aquí</Button>
            </Grid>
          </RootCard>
        </Card>
      </Grid>
      <Grid item xs={12} pb={5}>
        <RootBody>
          <ul>
            <li style={{ marginBottom: 45 }}>
              <Typography variant='body1' mb={2} style={{ color: 'white' }}>
                Si perteneces a una empresa y quieres ofrecer oportunidades de empleo a Profesionales de eventos escríbenos a {' '}
                <a href='mailto:empleo@prodevent.cl' className='aDefault'>
                  empleo@prodevent.cl
                </a>
              </Typography>
            </li>
            <li style={{ marginBottom: 45 }}>
              <Typography variant='body1' mb={2} style={{ color: 'white' }}>
                Si eres proveedor de la industria de Eventos, envía un correo a nuestro departamento comercial a {' '}
                <a href='mailto:empleo@prodevent.cl' className='aDefault'>
                  comercial@prodevent.cl
                </a>{' '}
                para recibir información sobre cómo ser parte de nuestros proveedores Premium.
              </Typography>
            </li>
          </ul>
        </RootBody>
      </Grid>
    </>
  )
}

export default Partners