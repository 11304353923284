import { maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
    padding-bottom: 14%;
    height: 0px;
    background-size: cover;
    position: relative;
    > h1 {
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50px; 
        transform: translateY(-50%);
        @media (max-width: ${maxSizeMobile}) {
            left: 20px;
        }
    }
    > h2 {
        text-transform: uppercase;
        font-size: 150px;
        font-family: 'Poppins-Thin' !important;
        opacity: 0.1;
        position: absolute;
        top: 50%;
        left: 50px; 
        transform: translateY(-50%);
        @media (max-width: ${maxSizeMobile}) {
            font-size: 50px;
            left: 20px;
        }
    }
`
