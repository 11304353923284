import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { Root, RootImage, RootItem, rootCarrousel } from './Carrousel.style'
import { maxSizeMobileNum } from '@/theme';

const diapo1 = require('@/assets/images/carrousel1/diapo1.webp')
const diapo2 = require('@/assets/images/carrousel1/diapo2.webp')
const diapo3 = require('@/assets/images/carrousel1/diapo3.webp')
const diapo4 = require('@/assets/images/carrousel1/diapo4.webp')
const diapo5 = require('@/assets/images/carrousel1/diapo5.webp')

const Carrousel = () => {
    var items = [
        diapo1,
        diapo2,
        diapo3,
        diapo4,
        diapo5
    ]

    // Responsive
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => { setWidth(window.innerWidth); };
        window.addEventListener('resize', handleResize);
        return () => {window.removeEventListener('resize', handleResize);};
    }, []);


    const isMobile = width <= maxSizeMobileNum
    return (
        <Root>
            <Slider
                className={rootCarrousel}
                speed={500}
                slidesToScroll={1}
                slidesToShow={!isMobile ? 5 : 3}
                centerMode
                focusOnSelect
                centerPadding={!isMobile ? '250px' : 0}

            >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Slider>
        </Root>
    )
}
function Item(props) {
    return (
        <RootItem>
            <RootImage style={{
                backgroundImage: `url(${props.item})`
            }}>
            </RootImage>
        </RootItem>
    )
}
export default Carrousel