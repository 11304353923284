import { maxSizeMobile } from '@/theme';
import styled from '@emotion/styled';

export const Root = styled.div`
    position: relative;
    margin-bottom: 26px;
    > div > div {
        width: 100%;
    }
`

export const CardGradient = styled.div`
    background-color: purple;
    width: 151px;
    height: 62px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: -30px;
    `

export const RootFilter = styled.div`
background-color: #370E74;
max-width: max-content;
padding: 0px 25px;
height: 30px;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
transition: all 0.2s;
:hover {
    opacity: 0.8;
}
:active {
    opacity: 0.5;
}
> p {
    user-select: none;
}
 @media (max-width: ${maxSizeMobile}) {
    position: absolute;
    top:10px;
    right: 10px;
 }
`