import React from 'react'
import { Root, isDefaultClass } from './Select.style'
import { BaseSelectProps, FormControl, InputLabel, MenuItem, Select as SelectMui } from '@mui/material'
import ChevronRight from '../Icons/ChevronRight'
import { css } from '@emotion/css'
import ChevronDown from '../Icons/ChevronDown'

interface Props extends BaseSelectProps {
    items: any
    label?: string,
    onChange?: any
    placeholder?: string
    value?: any
}

const Select: React.FC<Props> = ({
    items,
    label,
    id,
    variant,
    onChange,
    placeholder,
    value
}) => {

    const typeRootStyle = (variantSend) => {
        switch (variantSend) {
            case 'standard':
                return css`
                fieldset {
                    background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box;
                    border-radius:10px;
                    border: 2px solid transparent;
                    transition: all 0.15s;
                }
                input {
                    z-index: 1000 !important;
                }
                input::placeholder {
                    opacity: 0.3 !important; /* Firefox */
                    }
                    label {
                        color: black !important;
                    }
                    `
            case 'outlined':
                return css`
                 height: 33px !important;
              
                fieldset {
                    background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box;
                    border-radius:10px;
                    border: 2px solid transparent;
                    transition: all 0.15s;
                    height: 33px !important;
                    margin-top: 14px;
                }
                input {
                    height: 33px !important;
                }
                label {
                    height: 33px !important;
                    font-size: 16px;
                    color: #370E74 !important;
                    font-family: 'Poppins'
                }
                .MuiSelect-select {
                    padding: 0px;
                    margin-top: 29px;
                }
                .MuiSelect-icon  {
                    z-index: 1;
                    > path {
                        fill: #370E74 !important;
                    }
                }
                    `
            default:
                return typeRootStyle('standard')
                break;
        }
    }
    console.log('value',value)
    return (
        <Root className={!value ? isDefaultClass : null }>
            <FormControl fullWidth className={typeRootStyle(variant)}>
                <InputLabel id={id} shrink>{label}</InputLabel>
                <SelectMui
                    onChange={onChange}
                    IconComponent={(props) => <ChevronDown {...props} />}
                    labelId={id}
                    value={value ? value : 0}
                >
                    <MenuItem value={0} disabled>{placeholder}</MenuItem>
                    {
                        items.map(i => (
                            <MenuItem value={i.id}>{i.name}</MenuItem>
                        ))
                    }
                </SelectMui>
            </FormControl>
        </Root>
    )
}

export default Select