import React from 'react'
import { Root } from './HeaderTitle.style'
import { Typography } from '@mui/material'

const HeaderTitle = ({
  src,
  title,
  className
}: any) => {
  return (
    <Root className={className} style={{ backgroundImage: `url(${src})` }}>
      <Typography variant='h1' color="white">{title}</Typography>
      <Typography variant='h2' color="white">{title}</Typography>
    </Root>
  )
}

export default HeaderTitle