import Card from '@/components/Cards/Card/Card'
import Header from '@/modules/global/Header'
import Body from '@/modules/home/Body/Body'
import Footer from '@/modules/global/Footer/Footer'
import Form from '@/modules/login/Form'
import React from 'react'

const Home = () => {
  return (
    <>
    <div>
      <Body />
    </div>
    </>
  )
}

export default Home