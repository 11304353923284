import styled from '@emotion/styled';

export const Root = styled.div`
    > div {
        width: 100%;
    }
    .MuiInputLabel-root {
        margin-top: -20px;
        margin-left: -9px;
    }
    input {
    padding-left: 20px;
  }
`

export const RootStart = styled.div`
  position: relative;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
      color: black;
      z-index: 1;
      }
      ::before {
      z-index: 1;
    content: '';
    background-color: #4D0E77;
    height: 100%;
    width: 1px;
    position: absolute;
    right: -6px;
    top:0px;
  }
`