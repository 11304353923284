import { Grid, Typography } from '@mui/material'
import React from 'react'
import { ImageEvent } from './AboutUs.style'

const AboutUs = () => {
    const imageSrc = require('@/assets/images/prodevent-event.webp')
    return (
        <div>
            <Grid container mt={7} spacing={5}>
                <Grid item xs={12} md={6}>
                    <ImageEvent style={{ backgroundImage: `url(${imageSrc})` }} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant='h3' color={'#370E74'}>NUESTRA MISIÓN</Typography>
                    <Typography variant='body1' color={'#050505'} mt={3}>
                    Somos una red de profesionales de la industria de eventos en Chile, comprometidos con la profesionalización y el crecimiento constante. Ofrecemos capacitación, herramientas de trabajo, oportunidades laborales y apoyo a través de comunidades y redes de networking.
                    </Typography>
                    <Typography variant='body1' color={'#050505'} mt={3} mb={7}>
                    Buscamos establecer estándares de calidad y profesionalismo, promoviendo el crecimiento individual y colectivo de nuestros miembros.
                    </Typography>
                    <Typography variant='h3' color={'#370E74'}>BENEFICIOS</Typography>
                    <Typography variant='body1' color={'#050505'} mt={3}>
                        <b>Algunos de los beneficios de unirse a nuestra red.</b>
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant='body1' color={'#050505'} mt={3}>
                                Acceso a oportunidades de networking y colaboración con otros profesionales del sector.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1' color={'#050505'} mt={3}>
                                Participación en eventos exclusivos y seminarios de capacitación impartidos por expertos en la industria.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1' color={'#050505'} mt={3}>
                                Acceso a recursos y herramientas que facilitan el trabajo en la producción de eventos.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1' color={'#050505'} mt={3}>
                                Visibilidad y promoción de tu trabajo a través de nuestra plataforma y redes sociales.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant='body1' color={'#050505'} mt={3}>
                                Apoyo y asesoramiento de profesionales experimentados en el campo de eventos.
                            </Typography>
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </div>
    )
}

export default AboutUs