import React from 'react'
import { Root, RootBottom, RootTop, RootType, WatchIcon } from './Item.style'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const imageSrc = require('@/assets/images/news-banner-image.webp')
const watchSrc = require('@/assets/images/icons/watch-outline.webp')

const Item = () => {
    const navigate = useNavigate()
    return (
        <Root
            onClick={() => navigate('/news/new')} 
            style={{ backgroundImage: `url(${imageSrc})` }}>
            <RootTop>
                <WatchIcon src={watchSrc} />
                <Typography variant='body1' color={'white'}>7 febrero 2024</Typography>
            </RootTop>
            <RootBottom>
                <RootType>
                    <Typography variant='body1' color={'white'}>
                        Evento
                    </Typography>
                </RootType>
                <Typography variant='subtitle2' color={'white'}>
                    Lorem ipsum lorem ipsum lorem ipsum</Typography>
            </RootBottom>
        </Root>
    )
}

export default Item