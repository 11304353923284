import { maxSizeMobile } from '@/theme';
import styled from '@emotion/styled';

export const Root = styled.div`
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #370E74;
`


export const ItemImage = styled.div`
    > img {
        width: 100%;
    }
`
export const AvatarImage = styled.div`
    > img {
        width: 90px;
        box-shadow: 0px 3px 4px rgba(0,0,0,0.3);
    }
    margin-top: -45px;
    margin-left: 30px;
    
`
export const ButtonPaddingDownload = styled.div`
    margin-right: 20px;
    margin-top:40px;
    margin-bottom: 20px;
    > button {
        height: 30px;
        background-color: #0F66D7 !important;
    }
   span{
    padding-left: 20px; 
    padding-right: 20px; 
   }
   @media (max-width: ${maxSizeMobile}) {
    margin-top:20px;
    margin-bottom: 0px;
   }
   `
export const ButtonPadding = styled.div`
    margin-top:40px;
    margin-bottom: 20px;
    span{
        padding-left: 40px; 
        padding-right: 40px; 
    }
    @media (max-width: ${maxSizeMobile}) {
        margin-bottom:20px;
        margin-top: 0px;
    }
`