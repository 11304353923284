import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { theme } from '../theme';
import { Root, WhatsappIconFloat } from './Layout.style';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Drawer from '@/modules/global/Drawer';
import Header from '@/modules/global/Header';
import Footer from '@/modules/global/Footer/Footer';

const Layout = () => {
  const [open, setOpen] = React.useState(false);

  const WSIcon = require('@/assets/images/ws-icon.png')

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // MODE OFFLINE MOBILE
  const [width, setWidth] = useState(window.innerWidth);
  console.log('width', width)
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Root>
            <Drawer open={open} toggleDrawer={toggleDrawer} />
            <Header toggleDrawer={toggleDrawer} />
            <Outlet />
            <Footer />
            <WhatsappIconFloat src={WSIcon} />
          </Root>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  )
}

export default Layout