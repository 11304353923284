import React from 'react'
import { Root, RootFilter } from './Filters.style'
import Select from '@/components/Select/Select'
import { Grid, Typography } from '@mui/material'
import Input from '@/components/Input/Input'

const Filters = () => {
    return (
        <Root>
            <Grid container spacing={2}>
                <Grid item >
                    <RootFilter>
                        <Typography variant='body1' color={'white'}>
                            Licitación
                        </Typography>
                    </RootFilter>
                </Grid>
                <Grid item >
                    <RootFilter>
                        <Typography variant='body1' color={'white'}>
                            Empleos para productores
                        </Typography>
                    </RootFilter>
                </Grid>
                <Grid item >
                    <RootFilter>
                        <Typography variant='body1' color={'white'}>
                            Empleo para Staff de apoyo
                        </Typography>
                    </RootFilter>
                </Grid>
                <Grid item >
                    <RootFilter>
                        <Typography variant='body1' color={'white'}>
                            Prácticas
                        </Typography>
                    </RootFilter>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Filters