import { css } from '@emotion/css'
import React from 'react'
import { Button as ButtonMUI, Typography, colors } from '@mui/material'

interface Props {
    variant?: 'text' | 'outlined' | 'contained'
    children: any
    style?: any
    onClick?: any
    className?: any
    padding?: any
    fontSize?: any
}

export const Button: React.FC<Props> = ({
    variant,
    children,
    padding,
    fontSize,
    className,
    ...props
}) => {

    const typeRootStyle = () => {
        switch (variant) {
            case 'contained':
                return css`
                        background-color: #FF0082 !important;
                        > span {
                            color: white;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        border-radius: 20px !important;
                    `
            case 'outlined':
                return css`
                    border-radius: 20px;
                    height: 48px;
                    
                    background: linear-gradient(white, white) padding-box,
                                 linear-gradient(to right, #370e74, #f10d88) border-box; 
                                 span {
                                    color: #FF0082;
                                 }
                                 
                                 `
            default:
                return css``
                break;
        }
    }
    return (
        <ButtonMUI {...props} className={typeRootStyle() + ' ' + className} variant={variant}>
            <Typography variant='caption' style={{
                fontFamily: 'Poppins-Semibold', letterSpacing: 0.4,
                fontSize: fontSize ? fontSize : '13px'
            }}>{children}</Typography>
        </ButtonMUI>
    )
}
