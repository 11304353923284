import React from 'react'
import { Root } from './Filters.style'
import Select from '@/components/Select/Select'
import { Grid } from '@mui/material'
import Input from '@/components/Input/Input'

const Filters = () => {
    return (
        <Root>
            <Grid container spacing={4}>
                <Grid item xs={12} md>
                    <Select
                        label={'Zona'}
                        items={['Santiago']}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={12} md>
                    <Select
                        label={'Ciudad'}
                        items={['Santiago']}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={12} md>
                    <Select
                        label={'Tipo de beneficio'}
                        items={['Santiago']}
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={12} md mt={4}>
                    <Input 
                        placeholder='Buscar'
                        variant='outlined'
                    />
                </Grid>
            </Grid>
        </Root>
    )
}

export default Filters