import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`

`
export const RootItem = styled.div`
   display:flex;
   @media (max-width: ${maxSizeMobile}) {
      flex-direction: column;
   }
`


export const RootCard = styled.div`
   border: 1px solid #DADADA;
   padding: 30px;
   @media (max-width: ${maxSizeMobile}) {
      padding: 10px;
      margin-top: 30px;
   }
`

export const RootStar = styled.div`
   margin-bottom: 20px;
`

export const rootAvatar = css`
   > img {
      max-width: 100px;
   }
   @media (max-width: ${maxSizeMobile}) {
      > img {
         max-width: 57px;
      }
   }
`
