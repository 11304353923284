import React from 'react'
import { Root } from './List.style'
import { Grid, Typography } from '@mui/material'
import Card from '@/components/Cards/Card/Card'
import Item from '../Item/Item'
import { Button } from '@/components/Buttons/Button'

const List = () => {
    return (
        <Root>
            <Grid container spacing={4}>
                {Array.from(Array(8).keys()).map(i => (
                    <Grid item xs={12} md={4} container justifyContent={'center'} alignItems={'center'}>
                        <Item />
                    </Grid>
                ))}
                <Grid item xs={12} container justifyContent={'center'} alignItems={'center'} mb={3}>
                    <Button 
                        style={{padding: '10px 100px'}}
                    variant='contained'>Ver más</Button>
                </Grid>
            </Grid>
        </Root>
    )
}

export default List