import { maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const Root = styled.div`
  margin-top: -100px;
  @media (max-width: ${maxSizeMobile}) {
    margin-top: -90px;
  } 
`
export const FirstCard = styled.div`
  background-color: #370E74;
  padding: 0px 50px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  @media (max-width: ${maxSizeMobile}) {
    padding: 34px 30px;
    height: auto;
  } 
`
export const SecondCard = styled.div`
  background: hsla(264, 78%, 25%, 1);
  background: linear-gradient(45deg, hsla(264, 78%, 25%, 1) 0%, hsla(329, 97%, 51%, 1) 100%);
  background: -moz-linear-gradient(45deg, hsla(264, 78%, 25%, 1) 0%, hsla(329, 97%, 51%, 1) 100%);
  background: -webkit-linear-gradient(45deg, hsla(264, 78%, 25%, 1) 0%, hsla(329, 97%, 51%, 1) 100%);
  padding: 0px 50px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${maxSizeMobile}) {
    padding: 34px 30px;
    height: auto;
  } 
`