import Card from '@/components/Cards/Card/Card'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import Form from '@/modules/login/Form'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { PartyImage, Root, RootCard } from './index.style'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/provider/AuthProvider'

const bannerSrc = require('@/assets/images/login-banner-prodevent.webp')
const bannerPartySrc = require('@/assets/images/party-login-prodevent.webp')

const Login = ({ setIsAuth }) => {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if(auth.user) {
      navigate('/')
    }
  },[auth])

  return (
    <Root>
      <HeaderTitle
        title={'Iniciar sesión'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item md={6}>
          <PartyImage style={{ backgroundImage: `url(${bannerPartySrc})` }} />
        </Grid>
        <Grid item md={6} container justifyContent={'center'} alignItems={'center'} pl={4} pr={4}>
          <RootCard>
            <Card title={'Iniciar sesión'} paddingInterior={'50px 20px'} maxWidth={600}>
              <Form setIsAuth={setIsAuth} />
            </Card>
          </RootCard>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Login