import { maxSizeMobile } from '@/theme';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const Root = styled.div`
    max-width: 1073px;
    margin:auto;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: ${maxSizeMobile}) {
        padding: 30px 24px;
    }
`

export const rootLogoCompany = css`
    max-width: max-content !important;
    > img {
        width: 135px;
        padding-left: 20px;
        padding-right: 20px;
    }
    `

export const RootCard = styled.div`
    margin-bottom: 50px;
    > div > div  {
        width: 100% !important;
        padding: 20px 40px;
    }
`