import { maxSizeMobile } from '@/theme';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const Root = styled.div`
    position: relative;
    margin-top: 67px;
`

export const CardGradient = styled.div`
    background: linear-gradient(90deg, rgba(8,70,126,1) 0%, rgba(254,5,131,1) 100%);
    width: 151px;
    height: 62px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: -30px;
    @media (max-width: ${maxSizeMobile}) {
        left: 20px;
    }    
`

export const RootBody = css`
    @media (max-width: ${maxSizeMobile}) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`
