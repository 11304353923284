import React from 'react'
import { AvatarImage, ButtonPadding, ButtonPaddingDownload, ItemImage, Root } from './Item.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'

const Item = () => {
    const imageSrc = require('@/assets/images/study-top-head-card.webp')
    const avatarSrc = require('@/assets/images/stydy-avatar.webp')
    return (
        <Root>
            <Grid container>
                <Grid item xs={12}>
                    <ItemImage>
                        <img src={imageSrc} />
                    </ ItemImage>
                    <AvatarImage>
                        <img src={avatarSrc} />
                    </ AvatarImage>
                </Grid>
                <Grid item xs={12} pl={4} pr={4}>
                    <Typography variant='h6' color="#370E74" mb={2} mt={2}><b>
                        Nombre del hotel
                    </b></Typography>
                    <Typography variant='body1'>
                        Descripción del hotel Sed ut perspiciatis unde omnis iste natus error sit voluptatem , totam rem aperiam
                    </Typography>
                </Grid>
                <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12} md={6} container justifyContent={'center'} alignItems={'center'}>
                        <ButtonPaddingDownload>
                            <Button variant='contained'>Descargar malla</Button>
                        </ButtonPaddingDownload>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent={'center'} alignItems={'center'}>
                        <ButtonPadding>
                            <Button variant='contained'>Conoce más</Button>
                        </ButtonPadding>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Item