import { maxSizeMobile, maxSizeTablet, theme } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
    background: hsla(209, 96%, 19%, 1);
    background: linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    border-bottom: 1px solid rgba(255,255,255,0.25);
    margin-bottom: -1px;
    z-index: 1;
    position: relative;
`

export const Avatar = styled.img`
    width: 60px;
    @media (max-width: ${maxSizeTablet}) {
        width: 40px;
   }
`

export const LogoMobile = css`
    margin-left: 24px;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 300px !important;
    @media (max-width: ${maxSizeTablet}) {
        width: 161px !important;
   }
`

export const RootAccount = styled.div`
    display: flex;
    align-items: center;
    > div:first-child {
        @media (max-width: ${maxSizeTablet}) {
             position: absolute;
             top: -7px;
             left: 6px;
        }
    }
    > div:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;
        @media (max-width: ${maxSizeTablet}) {
             p {
                font-size: 17px !important;
            }
        }
    }
`

export const RootRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    > a {
        margin-left: 10px;
        margin-right: 10px;
    }
    @media (max-width: ${maxSizeTablet}) {
            a {
                font-size: 14px !important;
                margin-left: 9px;
                margin-right: 9px;
            }
        }
`
export const GridLogin = css`
    padding-left: 30px;
   @media (max-width: ${maxSizeTablet}) {
      display: none;
   }
`

export const GridLoginMobile = css`
    display: none;
    padding-left: 20px;
    padding-right: 20px;
      @media (max-width: ${maxSizeTablet}) {
       display: block;
       position: relative;
   }
`

export const GridMenu = css`
  padding-right: 30px;
  @media (max-width: ${maxSizeTablet}) {
       display: none;
    }
`
export const GridMenuMobile = css`
    display: none;
    > div {
        background-color: ${theme.palette.background.default};
        height: 60px;
        display: flex;
        justify-content: space-between;;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
    }
    @media (max-width: ${maxSizeTablet}) {
       display: block;
    }
`
export const headerButtonRoot = css`
  position: relative;
  height: 100%;
  > button {
    width: 100%;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: ${maxSizeMobile}) {
      height: 46px;
      justify-content: center;
      display: flex;
      align-items: center;
      > button  {
            width:auto;
            height:auto;
            padding-left: 5px;
            padding-right: 0px;
        }
      > button > span {
            font-size: 10px !important;
        }
    }
`
export const loginButton = css`
> button {
    margin-left: 25px;
    margin-right: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

  
`
export const menuButtonRoot = css`
    position: relative;
    height: 58px;
    > button {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
    }
`
export const menuHeader = css`
    border-top: 1px solid rgba(255,255,255,0.25);
`

export const itemActive = css`
background-color: rgba(255,255,255,0.1);
`
export const loginButtonClass = css`
    margin-left: 30px !important;
    margin-right: 30px !important;
    border-radius: 20px !important; 
    background: none !important;
    color: white !important;
    border-color: white  !important;
    button {
        background: none !important;
        color: white !important;
        border-color: white  !important;
    }
    > span {
        color: white !important;
        padding-left: 30px !important;
    padding-right: 30px !important;
    }
    @media (max-width: ${maxSizeMobile}) {
        margin-left: 12px !important;
        margin-right: 12px !important;
        height: 29px !important;
        border-radius: 100px !important; 
        > span {
            padding-left: 0px !important;
            padding-right: 0px !important;
            font-size: 10px !important;
        }
    }
`
export const rootMenuTop = css`
    @media (max-width: ${maxSizeMobile}) {
        display: none !important;
    }
    `
export const rootMenuBottom = css`
    @media (max-width: ${maxSizeMobile}) {
        display: none !important;
    } 
    `
export const RootMenuIcon = styled.div`
    border: 1px solid white;
    width: 48px;
    height: 33px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 19px;
    > svg {
        width: 24px;
    }
    > svg > path {
        fill:white;
    }
    `
export const rootMenuMobile = css`
        display: none !important;
        @media (max-width: ${maxSizeMobile}) {
            display: flex !important;
        } 
        `

export const menuHeaderMobile = css`
    @media (max-width: ${maxSizeMobile}) {
        border-bottom: 1px solid rgba(255,255,255,0.25);
    }
`