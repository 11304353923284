import styled from '@emotion/styled';

export const Root = styled.div`
    width: 100%;
    height: 0px;
    padding-bottom: 70%;
    background-size: cover;
    position: relative;
    > div:first-child {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    > div:last-child {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }
    ::before {
        content: '';
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    `
export const RootTop = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
`
export const WatchIcon = styled.img`
    margin-right: 10px;
    `
export const RootType = styled.div`
    background-color: #370E74;
    width: max-content;
    padding: 0px 20px;
    height: 25px;
    border-radius: 10px;
    margin-bottom: 10px;
`
export const RootBottom = styled.div`
    margin-bottom: 10px;
    margin-left: 20px;
`