import React from 'react'
import { Root, rootLogoCompany } from './index.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { RootType } from '@/modules/news/Item/Item.style'
import ChevronLeft from '@/components/Icons/ChevronLeft'
import GoBackButton from '@/components/Buttons/GoBackButton/GoBackButton'
import { useNavigate } from 'react-router-dom'
import Card from '@/components/Cards/Card/Card'
import { RootCard } from './index.style'

const companyLogoSrc = require('@/assets/images/company-logo-example.webp')

const Job = () => {
    const navigate = useNavigate()
    return (
        <Root>
            <Grid container>
                <Grid item xs={12}>
                    <GoBackButton
                        onClick={() => navigate('/jobs')}
                        title={'Volver a empleos'} />
                </Grid>
                <Grid item xs={12}>
                    <RootType>
                        <Typography variant='caption' color="white">
                            <b>Empleos para productores</b></Typography>
                    </RootType>
                </Grid>
                <Grid item xs={12} mb={1}>
                    <Typography variant='h4' style={{ textTransform: 'uppercase' }}>
                        Productor de Festival músical</Typography>
                </Grid>
                <Grid
                    className={rootLogoCompany}
                    item xs container justifyContent={'center'} alignItems={'center'}>
                    <img src={companyLogoSrc} />
                </Grid>
                <Grid item xs={'auto'} container justifyContent={'center'} flexDirection={'column'}>
                    <Typography variant='subtitle2' color='#370E74'>
                        Nombre empresa</Typography>
                    <Typography variant='subtitle2' color='#370E74'>
                        Providencia, Región Metropolitana
                    </Typography>
                    <Typography variant='caption'>
                        Publicada el 2 de feb  de 2024
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                    <Typography variant='body2'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta libero nec justo hendrerit, ac consequat sem ultricies. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras vel felis eleifend.
                        <br />
                        <br />
                        sodales elit sed, rutrum nunc. Mauris sit amet accumsan justo, nec tincidunt nisi. Nullam nec vehicula arcu. Proin sed enim nec dui efficitur finibus. Pellentesque sit amet varius risus. Integer tristique feugiat ligula, sit amet facilisis libero finibus et. Nunc dapibus tincidunt sapien a viverra. Vivamus vitae neque eget felis tempus ultrices. Vivamus vel accumsan turpis. Suspendisse euismod risus nec magna
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={6}>
                    <Typography variant='h6' color="#370E74">
                        Funciones del cargo:
                    </Typography>
                    <ul>
                        {
                            [
                                'Supervisar el funcionamiento de las marcas en el festival',
                                'Apoyo en terreno para montaje de las marcas',
                                'Responder a las necesidades de la marca',
                                'Control del Layout en su zona',
                                'Comunicación constante con Site para VB de activación y generador',
                                'Documentar Recibo Conforme de terreno',
                                'Punto te contacto en terreno entre la productora y Lotus'
                            ].map(i => (
                                <li>
                                    <Typography variant='body1'>{i}</Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <Grid item xs={12} mt={6}>
                    <Typography variant='h6' color="#370E74">
                        Requerimientos del cargo:
                    </Typography>
                    <ul>
                        {
                            [
                                'Estudios Técnicos o Universitarios en Administración, Comercial. (carreras a fin).',
                                'Experiencia como productor o coordinador de marcas en eventos masivos u otros eventos.',
                                'Conocimientos de normativas de seguridad y prevención de riesgos.',
                                'Habilidades para relacionarse.'
                            ].map(i => (
                                <li>
                                    <Typography variant='body1'>{i}</Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <Grid item xs={12} mt={6}>
                    <Typography variant='h6' color="#370E74">
                        Condiciones:
                    </Typography>
                    <ul>
                        {
                            [
                                'Contrato indefinido.',
                                'Jornada laboral de lunes a viernes,',
                                'Horario laboral de 08:30 a 18:30.',
                                'Ubicación en Providencia, Región Metropolitana.',
                                'Beneficios: seguro complementarios de salud, dental, seguro de vida, catastrófico y aguinaldos.',
                                'Cortesías para Shows según disponibilidad.'
                            ].map(i => (
                                <li>
                                    <Typography variant='body1'>{i}</Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <Grid item xs={12} mt={6}>
                    <RootCard>
                        <Card>
                            <Grid container spacing={3}> 
                                <Grid item xs container alignItems={'center'}>
                                    <Typography variant='body1' style={{marginRight:20}}>
                                        Si deseas postular, envíanos tu CV
                                    </Typography>
                                    <Button variant='outlined'>Subir CV</Button>
                                </Grid>
                                <Grid item xs container justifyContent={{md: 'flex-end'}} alignItems={'center'} >
                                    <Button variant='contained'>Postular</Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </RootCard>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Job