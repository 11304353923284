import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import React from 'react'

const bannerSrc = require('@/assets/images/support-banner-prodevent.webp')
const logoAviSrc = require('@/assets/images/logos/logo_avi_events_prodevent.webp')
const logoRuahSrc = require('@/assets/images/logos/logo_ruah_producciones_prodevent.webp')
const Companies = () => {
    return (
        <Root>
            <Grid container item xs={12} spacing={5} pl={6} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <LogoImage src={logoAviSrc} />
                </Grid>
                <Grid item>
                    <LogoImage src={logoRuahSrc} />
                </Grid>
            </Grid>
        </Root>
    )
}

export default Companies

export const Root = styled.div`
   margin-top: 30px;
   padding-bottom: 80px;
`
export const LogoImage = styled.img`
    width: 250px;
    height: 100px;
    object-fit: contain;

`