import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import styled from "@emotion/styled";

export const RootBodyList = styled.div`
   margin-left: 4%;
   margin-right: 4%;
   margin-top: 50px;
`
export const RootArticles = styled.div`
   margin-left: 53px;
   margin-right: 53px;
   margin-top: 50px;
   @media (max-width: ${maxSizeMobile}) {
      margin-left: 23px;
      margin-right: 23px;
        } 
`