export const scrollToId = (id) => {
    var element = document.getElementById(id);
    if (element) {
      var headerOffset = 40;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }