import Card from '@/components/Cards/Card/Card'
import { Avatar, Grid, Typography } from '@mui/material'
import React from 'react'
import { RootCard, RootStar, rootAvatar } from './Comments.style'
import Carrousel from './Carrousel/Carrousel'

const startSrc = require('@/assets/images/icons/star.webp')
const startHalfSrc = require('@/assets/images/icons/star-half.webp')
const startOutlineSrc = require('@/assets/images/icons/star-outline.webp')
const avatarSrc = require('@/assets/images/avatar.webp')

const Comments = () => {
    return (
        <div>
            <Grid container mt={6} mb={6}>
                <Grid item xs={12} md={6}>
                    <Typography variant='h4' color="#370E74">CLIENTES</Typography>
                    <Typography variant='h2' color="#370E74">TESTIMONIOS</Typography>
                    <Typography variant='body1'>
                    Estos testimonios reflejan el impacto positivo que Prodevent Chile ha tenido en sus clientes, socios y proveedores.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Carrousel />
                </Grid>
            </Grid>
        </div>
    )
}

export default Comments