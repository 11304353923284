import Axios from 'axios';
import config from '@/config';
import errorLog from '@/utils/errorLog';

export interface Request {
  token: string;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default async (req: Request): Promise<Response | null> => {
  const path = '/user/token'
  const url = config.API_URL + path
  const body = req
  const headers = { ...config.APIHEADER }
  return Axios.post(url, body,  { headers })
      .then(response => {
          return response.data
      })
      .catch(error => {
          errorLog({ path, url, req: null, error })
          return error
      })
}

interface Response {
  data: any
  token?: string
  error?: {
      errorCode: number
      errorMessage: string
  }
}
