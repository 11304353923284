import React from 'react'

const MenuIcon = ({...props}) => {
  return (
    <svg {...props} width="34" height="34" viewBox="0 0 24 24" fill="none">
    <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" fill="black"/>
    </svg>
    
  )
}

export default MenuIcon