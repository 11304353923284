import React from 'react'
import { CollageImage, Root, RootBody, RootCard, boldText } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import Card from '@/components/Cards/Card/Card'
import Filters from '@/modules/jobs/Filters/Filters'
import Item from '@/modules/jobs/Item/Item'

const bannerSrc = require('@/assets/images/jobs-banner-prodevent.webp')
const collageSrc = require('@/assets/images/collage-jobs-banner-prodevent.webp')

const Jobs = () => {
  return (
    <Root>
      <HeaderTitle
        title={'EMPLEO'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item  xs={12} md={9}>
          <RootBody>
            <Typography variant='h4' mb={4}>
              PORTAL DE EMPLEO, PRÁCTICAS Y LICITACIONES
            </Typography>
            <Typography variant='body1' mb={4}>
              El Portal de Empleo de PRODEVENT CHILE es un servicio en línea que centraliza la información sobre empleos, prácticas y licitaciones en la industria de producción de eventos para nuestros asociados. Nuestro objetivo es conectar a profesionales en búsqueda de oportunidades laborales con empresas interesadas en incorporar personal en este sector dinámico.
            </Typography>
            <Typography variant='body1' mb={4}>
              <span className={boldText}>
                Beneficios para los Socios:{' '}
              </span>
              Los socios pueden ingresar su CV, explorar ofertas laborales vigentes y postularse a aquellas que les interesen.
            </Typography>
            <Typography variant='body1' mb={4}>
              <span className={boldText}>
                Beneficios para las Empresas:{' '}
              </span>
              Las empresas pueden anunciar sus ofertas laborales gratuitamente y acceder al servicio de reclutamiento de personal. Además, el Portal ofrece información sobre licitaciones abiertas de empresas públicas y privadas.
            </Typography>
            <Typography variant='body1' mb={4}>
              <span className={boldText}>
                Prácticas Laborales:{' '}
              </span>
              También proporcionamos información sobre prácticas laborales para estudiantes de producción de eventos, facilitando conexiones profesionales y la adquisición de conocimientos para su futuro.
            </Typography>
            <Typography variant='body1' mb={4}>
              <span className={boldText}>
                Promoción de la Profesión:{' '}
              </span>
              Buscamos promover la profesión de productores de eventos y ofrecer oportunidades laborales para nuestra red de profesionales. Es importante destacar que esta es una profesión que requiere una inversión de al menos 2 años de estudios en institutos profesionales o universidades reconocidos por el Ministerio de Educación de Chile, información que muchas empresas y personas desconocen.
            </Typography>
            <Typography variant='body1' mb={4} color={'#370E74'}>
              Esperamos que este Portal sea una herramienta centralizada y efectiva para empresas y profesionales por igual. ¡Únete a nuestra red y encuentra las mejores oportunidades en la industria de eventos!
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12} md={3} container justifyContent={'center'} alignItems={'center'}>
          <CollageImage src={collageSrc} />
        </Grid>
        <Grid item xs={12}>
          <RootCard>
            <Card paddingInterior={40}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h4'>Oportunidades</Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Filters />
                </Grid>
                <Grid item xs={12} mt={5}>
                  {Array.from(Array(10).keys()).map(i => (
                    <Item />
                  ))}
                </Grid>
              </Grid>
            </Card>
          </RootCard>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Jobs