import { maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const textGradient = css`
  background: hsla(264, 78%, 25%, 1);

  background: linear-gradient(90deg, hsla(264, 78%, 25%, 1) 0%, hsla(328, 90%, 50%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(264, 78%, 25%, 1) 0%, hsla(328, 90%, 50%, 1) 100%);

  background: -webkit-linear-gradient(0deg, hsla(264, 78%, 25%, 1) 0%, hsla(328, 90%, 50%, 1) 100%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const RootCard = styled.div`
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 8px, transparent 8px) 0% 0%/10px 10px no-repeat,
              radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 8px, transparent 8px) 100% 0%/10px 10px no-repeat,
              radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 8px, transparent 8px) 0% 100%/10px 10px no-repeat,
              radial-gradient(circle at 0 0, #ffffff 0, #ffffff 8px, transparent 8px) 100% 100%/10px 10px no-repeat,
              linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 20px) no-repeat,
              linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 20px) calc(100% - 4px) no-repeat,
              linear-gradient(90deg, #370e74 0%, #f10d88 100%);
  border-radius: 10px;
  padding: 40px 29px;
  height: 180px;
  @media (max-width: ${maxSizeMobile}) {
    height: auto;
  }
`

export const GradientIconImage = styled.img`
  width: 57px;
  height: 57px;
  object-fit: contain;
`
export const rootButton = css`
  padding-left: 100px !important; 
  padding-right: 100px !important; 
  border-radius: 10px !important;
  @media (max-width: ${maxSizeMobile}) {
    padding-left: 20px !important; 
  padding-right: 20px !important; 
  border-radius: 20px !important;
  }
`