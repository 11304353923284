import React, { useState } from 'react'
import { Root, goBackMod, headerMod, rootBg } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import GoBackButton from '@/components/Buttons/GoBackButton/GoBackButton'
import { useNavigate } from 'react-router-dom'

const bannerSrc = require('@/assets/images/partners-banner-prodevent.webp')

const Partner = () => {
  const navigate = useNavigate()
  return (
    <Root>
      <HeaderTitle
        title={'ZONA DE SOCIOS'}
        src={bannerSrc}
        className={headerMod}
      />
      <Grid container>
        <Grid container item xs={12} className={rootBg} spacing={2}>
          <Grid item xs={12}>
            <GoBackButton
              className={goBackMod}
              onClick={() => navigate('/partners')}
              title={'Volver atrás'} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' color={'white'} mb={3}>
              FORMULARIO DE INSCRIPCIÓN
            </Typography>
            <Typography variant='body1' color={'white'}>
              <b>Nota importante:</b><br />
              Para ser socio de PRODEVENT CHILE, es estrictamente necesario cumplir con ciertos requisitos. Se requerirá comprobar el cargo a través de un título de estudios, cartas de recomendación o un certificado de alumno de alguna institución de educación. No se admitirá la membresía de personas que no cumplan con estos requisitos, reservándonos el derecho de admisión.
              <br /><br />
              En nuestra red, valoramos la excelencia y la profesionalidad en todo lo que hacemos. Hemos establecido altos estándares en la calidad de los eventos que producimos, y para mantener ese nivel de excelencia, hemos observado que contar con profesionales con una formación específica en producción de eventos es fundamental.
              <br /><br />
              Por ello, hemos decidido enfocarnos en la asociación de personas que hayan estudiado y obtenido un título profesional en el campo de producción de eventos. Creemos que este enfoque no solo garantiza un conocimiento sólido de las mejores prácticas y técnicas en la industria, sino que también contribuye significativamente a la reputación y éxito de nuestra industria y su futuro.
              Entendemos que existen diferentes caminos para adquirir experiencia, pero creemos firmemente que la formación académica proporciona una base sólida que beneficia tanto a los individuos como a la empresa en su conjunto. Este enfoque nos permite ofrecer servicios de alta calidad a nuestros clientes y mantenernos a la vanguardia de las últimas tendencias y avances en el mundo de la producción de eventos.
              <br /><br />
              Agradecemos tu comprensión y apoyo en este asunto. Estamos comprometidos a mantener la calidad y profesionalismo que todos nuestros clientes esperan de nosotros y creemos que esta decisión contribuirá significativamente a ese objetivo.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={6}>
            
          </Grid>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Partner