import React from 'react'
import Banner from './Banner/Banner'
import BannerCards from './BannerCards/BannerCards'
import AboutUs from './AboutUs/AboutUs'
import { RootArticles } from './Body.style'
import LineWorks from './LineWorks/LineWorks'
import Comments from './Comments/Comments'
import LastEvent from './LastEvent/LastEvent'
import Companies from './Companies/Companies'


const Body = () => {
  return (
    <div>
      <Banner />
      <RootArticles>
        <BannerCards />
        <AboutUs />
        <LineWorks />
        <Comments />
        <LastEvent />
        <Companies />
      </RootArticles>
    </div>
  )
}

export default Body