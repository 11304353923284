import create, { Request } from '@/api/users/create'
import { Button } from '@/components/Buttons/Button'
import Input from '@/components/Input/Input'
import { useAuth } from '@/provider/AuthProvider'
import { Fn } from '@/utils/validations'
import { Grid, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Form = ({ setIsAuth }) => {
  const [name, setName] = useState(null)
  const [last_name, setLast_name] = useState(null)
  const [email, setEmail] = useState(null)
  const [reEmail, setReEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [rePassword, setRePassword] = useState(null)
  const [error, setError] = useState('')
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState(null)

  const auth = useAuth()

  const navigate = useNavigate()

  const onSubmit = () => {
    setError('')
    if (!email) {
      return setError('Debes ingresar un correo')
    }
    if (!Fn.validateEmail(email)) {
      return setError('Debes ingresar un correo válido')
    }
    if (email !== reEmail) {
      return setError('Los correos ingresadas no coinciden')
    }
    if (!password) {
      return setError('Debes ingresar una contraseña')
    }
    if (password.length < 6) {
      return setError('La contraseña debe ser mínimo de 6 letras')
    }
    if (password !== rePassword) {
      return setError('Las contraseñas ingresadas no coinciden')
    }
    createClient()
  }


  const createClient = async () => {
    setError('')
    let req: Request = {
      name,
      last_name,
      email,
      password
    }
    setError('')
    setLoad(true)
    const res = await create(req)
    console.log("res register =>", res)
    if (!res) {
      setLoad(false)
      return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde')
    }
    if (!res.data) {
      if (res.error) {
        setLoad(false)
        if (res.error.errorMessage) {
          return setError(res.error.errorMessage)
        } else {
          setLoad(false)
          return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde 02')
        }
      } else {
        setLoad(false)
        return setError('En estos momentos tu cuenta no pudo ser creada, por favor intente más tarde 01')
      }
    }
    localStorage.setItem('token', res.token)
    setIsAuth(true)
    setMessage('¡Tu cuenta ha sido creada!, Ingresando...')
    setTimeout(() => {
      setLoad(false)
      auth.signin(res.data, () => {
        navigate('/')
      })
    }, 2000)
  }

  return (
    <div>
      <Grid container spacing={3}>
        {/* <Grid item xs={6}>
          <Input
            label='Nombre'
            placeholder='Ingresa tu nombre'
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label='Apellido'
            placeholder='Ingresa tu apellido'
            value={last_name}
            onChange={(e) => setLast_name(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label='Correo electrónico'
            placeholder='Ingresa tu correo'
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label='Repite tu correo'
            placeholder='Ingresa tu correo nuevamente'
            value={reEmail}
            onChange={(e) => setReEmail(e.currentTarget.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Input
            label='Contraseña'
            placeholder='Ingresa tu contraseña'
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label='Repite tu contraseña'
            placeholder='Ingresa tu contraseña nuevamente'
            type="password"
            value={rePassword}
            onChange={(e) => setRePassword(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12} container justifyContent={'center'} mt={3}>
          <Button onClick={onSubmit} variant='contained' style={{ width: '100%', height: 57, borderRadius: 20 }}>
            <b>Crear cuenta</b>
          </Button>
          {
            error && <Typography variant='body1' color="red" mt={3}>{error}</Typography>
          }
        </Grid>
        <Grid item xs={12} container justifyContent={'center'}>
          <Link to={'/login'}>
            <Typography variant='body1'>¿Ya tienes cuenta? <b>Iniciar sesión aquí</b></Typography>
          </Link>
        </Grid>
      </Grid>
      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}

export default Form