import React from 'react'
import { Root } from './GoBackButton.style'
import { Button } from '../Button'
import ChevronLeft from '@/components/Icons/ChevronLeft'

interface Props {
    title: string
    onClick: any
    className?: any
}

const GoBackButton = ({
    title,
    onClick,
    className
}:Props) => {
    return (
        <Root onClick={onClick} className={className}>
            <Button variant='text' style={{ color: '#777777', marginTop: 4, }}>
                <ChevronLeft /> {title}</Button>
        </Root>
    )
}

export default GoBackButton