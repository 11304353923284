import { maxSizeMackbook, maxSizeMobile } from "@/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const Root = styled.div`
   width: 100%;
   overflow: hidden;
   `
export const RootItem = styled.div``

export const RootImage = styled.div`
   width: 100%;
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   background-blend-mode: saturation;
   padding-bottom: 96%;
   background-blend-mode: saturation;
   @media (min-width: ${maxSizeMackbook}) {
      padding-bottom: 96%;
   }
   @media (max-width: ${maxSizeMobile}) {
      padding-bottom: 90%;
   }
`

export const rootCarrousel = css`
   transform: scale(1.6)  translateX(15%);
   margin-top: 100px;
   margin-bottom: 150px;
   .slick-slide {
      filter: grayscale(100%);
      
   }
   .slick-slide.slick-current {
      transform: scale(1.2);
      filter: inherit;
      z-index: 100;
      position: relative;
   }
   @media (max-width: ${maxSizeMackbook}) {
      transform: scale(1.6)  translateX(13%);
   }
   @media (max-width: ${maxSizeMobile}) {
      transform: inherit;
      
   }
`

