import login from '@/api/users/login'
import { Button } from '@/components/Buttons/Button'
import Input from '@/components/Input/Input'
import { useAuth } from '@/provider/AuthProvider'
import { Fn } from '@/utils/validations'
import { Grid, Snackbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Form = ({setIsAuth}) => {
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [error, setError] = useState('')
  const [load, setLoad] = useState(false)
  const [message, setMessage] = useState(null)
  const [openModalForgot, setOpenModalForgot] = useState(false)

  const auth = useAuth()

  const navigate = useNavigate()

  const onSubmit = async () => {
      setError('')

      if (!email) {
          return setError('Te falta ingresar un correo')
      }
      if (!Fn.validateEmail(email)) {
          return setError('Debes ingresar un correo válido')
      }
      if (!password) {
          return setError('Te falta ingresar una contraseña')
      }
      if (password.length < 6) {
          return setError('La contraseña debe ser mínimo 6 letras')
      }

      setLoad(true)
      const res = await login({ email, password })
      console.log("res login =>", res)
      if (!res) {
          setLoad(false)
          return setError('Error en el sistema')
      }
      if (!res.data) {
          if (res.error) {
              setLoad(false)
              if (res.error.errorMessage) {
                  return setError(res.error.errorMessage)
              } else {
                  setLoad(false)
                  return setError('Error')
              }
          } else {
              setLoad(false)
              return setError('Error')
          }
      }

      localStorage.setItem('token', res.token)
      setIsAuth(true)
      setMessage('¡Datos correctos! Ingresando...')
      setTimeout(() => {
          setLoad(false)
          auth.signin(res.data, () => {
              navigate('/')
          })
      }, 2000)
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4' color={'#370E74'}>BIENVENIDO(A)</Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            label='Correo electrónico'
            placeholder='Ingresa tu correo'
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label='Contraseña'
            placeholder='Ingresa tu contraseña'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12} container justifyContent={'flex-end'} mb={3}>
          <Typography variant='caption' color="#777E90">¿Olvidaste tu contraseña?</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent={'center'}>
          <Button onClick={onSubmit} variant='contained' style={{ width: '100%', height: 57, borderRadius: 20 }}>
            <b>Iniciar sesión</b>
          </Button>
          {
            error && <Typography variant='body1' color="red" mt={3}>{error}</Typography>
          }
        </Grid>
        <Grid item xs={12} container justifyContent={'center'}>
          <Link to={'/register'}>
            <Typography variant='body1'>¿No tienes cuenta? <b>Inscríbete aquí</b></Typography>
          </Link>
        </Grid>
      </Grid>
      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => setMessage(null)}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  )
}

export default Form