import styled from '@emotion/styled';

export const Root = styled.div``
export const ItemImage = styled.div`
    position: relative; 
    > img {
        width: 100%;
    }
`
export const ButtonPadding = styled.div`
   span{
    padding-left: 40px; 
    padding-right: 40px; 
    padding-top: 3px;
    padding-bottom: 3px;
   }
`

export const RootStar = styled.div`
   margin-bottom: 20px;
`
export const RootFilter = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #370E74;
    max-width: max-content;
    padding: 0px 25px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
        opacity: 0.8;
    }
    :active {
        opacity: 0.5;
    }
    > p {
        user-select: none;
    }
`