import { Grid, Typography } from '@mui/material'
import React from 'react'
import { GradientIconImage, RootCard, rootButton, textGradient } from './LineWorks.style'
import { Button } from '@/components/Buttons/Button'

const LineWorks = () => {
    return (
        <div>
            <Grid container mt={12} spacing={5}>
                <Grid item xs={12} container justifyContent={'center'} mb={2}>
                    <Typography className={textGradient} variant='h3'>
                        CONTAMOS CON 7 LÍNEAS DE TRABAJO
                    </Typography>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    {
                        [
                            {
                                src: require('@/assets/images/gradient_icons/courses.webp'),
                                title: 'Cursos y capacitaciones',
                                body: 'Ofrecemos cursos y capacitaciones en diversas áreas relacionadas con la producción de eventos, contribuyendo al crecimiento profesional de nuestros miembros.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/jobs.webp'),
                                title: 'Oportunidades de empleo',
                                body: 'Facilitamos oportunidades laborales conectando a empresas con profesionales competentes dentro de nuestra comunidad.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/business.webp'),
                                title: 'Oportunidades de negocios',
                                body: 'Facilitamos el acceso a licitaciones para profesionales con negocios propios.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/practicas.webp'),
                                title: 'Prácticas profesionales',
                                body: 'Proporcionamos opciones de prácticas profesionales para estudiantes en nuestra comunidad, en colaboración con empresas en convenio.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/benefy_pro.webp'),
                                title: 'Beneficios profesionales',
                                body: 'Ofrecemos beneficios profesionales a través de convenios con proveedores de la industria, garantizando precios especiales para la realización de eventos exitosos en todo Chile.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/benefy.webp'),
                                title: 'Beneficios personales',
                                body: 'Tenemos convenios con una variedad de servicios y productos en todo el país, ofreciendo precios especiales exclusivos para nuestra comunidad.'
                            },
                            {
                                src: require('@/assets/images/gradient_icons/red.webp'),
                                title: 'Red colaborativa nacional',
                                body: 'Únete a la red colaborativa nacional más grande de productores profesionales de eventos en Chile. Te ofrecemos oportunidades de networking en diversas actividades durante todo el año, acceso exclusivo a nuestras redes sociales, newsletters y eventos diseñados para tu crecimiento profesional y mantenerte a la vanguardia de la industria.'
                            },
                        ].map((i, index) => (
                            <Grid item xs={12} md={index === 6 ? 12 : 4}>
                                <RootCard>
                                    <Grid container>
                                        <Grid item xs={4} md container justifyContent={'center'} alignItems={'center'}>
                                            <GradientIconImage src={i.src}
                                            />
                                        </Grid>
                                        <Grid item xs={8} md={9} container alignItems={'center'}>
                                            <Typography variant='h6' className={textGradient}>
                                                {index + 1}. {i.title}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography variant='body2' mt={5} mb={1}>
                                        {i.body}
                                    </Typography>
                                </RootCard>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item xs={12} container justifyContent={'center'} mb={6}>
                    <Button variant={'contained'} className={rootButton}>Quiero ser parte</Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default LineWorks