import React from 'react'
import { CardGradient, Root, RootFilter } from './Item.style'
import Card from '@/components/Cards/Card/Card'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { useNavigate } from 'react-router-dom'

const Item = () => {
    const navigate = useNavigate()
    const companyLogoSrc = require('@/assets/images/company-logo-example.webp')

    return (
        <Root>
            <Card withBorder paddingInterior={'50px 10px'}>
                <Grid container >
                    <Grid item xs={12} md={3} container justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                        <img
                            src={companyLogoSrc}
                        />
                    </Grid>
                    <Grid item xs container>
                        <Grid item xs={12} container justifyContent={'flex-end'} mb={-5} mr={3}>
                            <RootFilter>
                                <Typography variant='body1' color={'white'}>
                                    Licitación
                                </Typography>
                            </RootFilter>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' color="#370E74">Nombre de la oportunidad</Typography>
                            <Typography variant='body1'>
                                Las Condes, Región Metropolitana,
                            </Typography>
                            <Typography variant='body1' color="#370E74" mt={3} mb={3}>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam
                            </Typography>
                            <Button
                                onClick={() => navigate('/jobs/job')}
                                variant='contained'>Ver más</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Card>
        </Root>
    )
}

export default Item