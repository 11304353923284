import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Typography, Grid, Avatar } from '@mui/material'
import { Root, RootCard, RootStar, rootAvatar } from './Carrousel.style'

const startSrc = require('@/assets/images/icons/star.webp')

var items = [
    {
        desc: '“Estar en Prodevent Chile ha sido una gran oportunidad para nuestro negocio. Su compromiso con la calidad y la excelencia nos ha ayudado a crecer y a establecernos como proveedores confiables en la industria de eventos."',
        autor: "Andrés Sepúlveda",
        cargo: 'Senior Sales Manager',
        secondary: 'AVI EVENTS RENTAL -Proveedor asociado',
        siglas: 'AS'
    },
    {
        desc: 'Prodevent Chile ha sido de gran aporte para nosotros ya que cuenta con un equipo profesional y confiable, esto ayuda a fortalecer tanto los servicios de nuestra empresa como la confianza de nuestros clientes hacia nosotros.',
        autor: "Francisco Herrán Cisternas",
        cargo: 'Director - Ruah Producciones',
        secondary: '',
        siglas: 'FH'

    },
]

const Carrousel = () => {
    return (
        <Root>
            <Carousel
                swipe={false}
                animation='slide'
                navButtonsAlwaysVisible>
                {
                    items.map((i) => <Item 
                        desc={i.desc}
                        autor={i.autor}
                        cargo={i.cargo}
                        secondary={i.secondary}
                        siglas={i.siglas}
                    />)
                }
            </Carousel>
        </Root>
    )
}
function Item({
    desc,
    autor,
    cargo,
    secondary,
    siglas
}) {
    return (
        <RootCard>
            <RootStar>
                <img src={startSrc} />
                <img src={startSrc} />
                <img src={startSrc} />
                <img src={startSrc} />
                <img src={startSrc} />
                {/* <img src={startHalfSrc} /> */}
            </RootStar>
            <Typography variant='body1'>
                {desc}
            </Typography>
            <Grid container mt={3}>
                <Grid item className={rootAvatar}>
                    <Avatar sx={{ width: 60, height: 60, bgcolor: 'orange' }} >{siglas}</Avatar>
                </Grid>
                <Grid item xs container justifyContent={'center'} flexDirection={'column'} ml={3}>
                    <Typography variant='h6' fontSize={17} color="#370E74"><b>{autor}</b></Typography>
                    <Typography variant='body1' color="#370E74">{cargo}</Typography>
                    <Typography variant='caption' color="#370E74">{secondary}</Typography>
                </Grid>
            </Grid>
        </RootCard>
    )
}
export default Carrousel