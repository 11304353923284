import React from 'react'
import { Root, RootContainer, RootRight, RootTitle } from './Card.style'
import { Typography } from '@mui/material'
import { theme } from '@/theme'

interface Props {
    children: any
    maxWidth?: number
    withContainer?: boolean
    paddingVertical?: number
    title?: string
    paddingInterior?: number | string
    withBorder?: boolean
    right?: any
    className?: string
}

const Card: React.FC<Props> = ({
    children,
    maxWidth,
    withContainer,
    paddingVertical,
    paddingInterior,
    title,
    withBorder,
    right,
    className
}) => {

    return (
        <RootContainer
            className={className || null}
            style={{
                paddingTop: paddingVertical,
                paddingBottom: paddingVertical,
            }}>
            <Root style={{
                maxWidth,
                padding: paddingInterior,
                border: withBorder ? `solid 2px purple` : 'inheritr'
            }}>
                {children}
            </Root>
        </RootContainer>
    )

}

export default Card