import React from 'react'
import { CardGradient, Root, rootBody } from './Item.style'
import Card from '@/components/Cards/Card/Card'
import { Grid, Typography } from '@mui/material'

const Item = () => {
    const portadaSrc = require('@/assets/images/benefy_portada.webp')
    return (
        <Root>
            <Card withBorder paddingInterior={'50px 10px'}>
                <Grid container >
                    <Grid item md={3} container justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                        <CardGradient>
                            <Typography variant='h6' color={'white'}>20% dto</Typography>
                        </CardGradient>
                        <img 
                            src={portadaSrc}
                        />
                    </Grid>
                    <Grid item xs container className={rootBody}>
                        <Grid item xs={12}>
                            <Typography variant='h5' color="#370E74">Nombre beneficio</Typography>
                            <Typography variant='body1'>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={3}>
                            <Grid item md={6}>
                                <Typography variant='body1' color="#370E74">
                                    <b>José Manuel Herrera Urbina</b>
                                </Typography>
                                <Typography variant='body1' color="#370E74">
                                    <b>Santiago</b>
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant='body1' color="#370E74">
                                    <b>+569 3245 8279</b>
                                </Typography>
                                <Typography variant='body1' color="#370E74">
                                    <b>contacto@gmail.com</b>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Root>
    )
}

export default Item