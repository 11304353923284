import React, { useEffect, useRef, useState } from 'react'
import { Root, SquarePreview } from './InputFile.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'

const InputFile = ({
    title,
    desc
}) => {
    const [file, setFile] = useState<File>(null);
    const [preview, setPreview] = useState(null)
    const fileRef: any = useRef()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!file) {
            setPreview(undefined)
            return
        }

        const objectUrl: any = URL.createObjectURL(file)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    const onChange = (event: React.FormEvent) => {
        const files = (event.target as HTMLInputElement).files

        if (files && files.length > 0) {
            setFile(files[0])
        }
    }
    return (
        <Root>

            <Grid item xs={12} container spacing={3}>
                <Grid item>
                    <SquarePreview >
                       {preview && <img src={preview} style={{ width: '80%', height: '80%', objectFit:'contain' }} />}
                    </SquarePreview>
                </Grid>
                <Grid item xs>
                    <Typography variant='body1' color={'#370E74'}>
                        <b>{title}</b></Typography>
                    <Typography variant='body1'>
                        {desc}
                    </Typography>
                    <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                        JPG | PNG | PDF (MAX 20 MB)
                    </Typography>
                </Grid>
                <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button onClick={() => fileRef.current.click()} variant='contained' style={{ height: 62 }}>
                        Elegir
                    </Button>
                </Grid>
            </Grid>
            <input
                onChange={onChange}
                ref={fileRef}
                type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" hidden />
        </Root>
    )
}

export default InputFile