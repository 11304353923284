import React from 'react'
import { Root, RootBody, RootCard } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import Card from '@/components/Cards/Card/Card'
import Filters from '@/modules/provider/Filters/Filters'
import Item from '@/modules/provider/Item/Item'

const bannerSrc = require('@/assets/images/provider-banner-prodevent.webp')

const Provider = () => {
  return (
    <Root>
      <HeaderTitle
        title={'PROVEEDORES'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h4' mb={4}>
              PROVEEDORES SOCIOS RECOMENDADOS
            </Typography>
            <Typography variant='body1' mb={4}>
              Los proveedores son esenciales en nuestra industria, ya que ofrecen productos y servicios clave para cada proyecto. En PRODEVENT CHILE, nos esforzamos por facilitar a nuestros miembros el acceso a proveedores confiables, comprometiéndose con respuestas rápidas y un alto nivel de servicio.
              <br />
              <br />
              Todos los asociados a PRODEVENT CHILE disfrutan de descuentos exclusivos con nuestros proveedores a nivel nacional. Estos descuentos contribuyen a reducir costos y garantizan trabajos de alta calidad. Nuestros proveedores pasan por un riguroso proceso de validación, lo que brinda confianza y calidad en todo Chile.
            </Typography>
            <Typography variant='body1' mb={4} color="#370E74">
              <b>
                Recuerda mencionar tu membresía y presentar tu credencial para hacer efectivo los descuentos y beneficios.
              </b>
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <RootCard>
            <Card paddingInterior={40}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h4'>Proveedores</Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Filters />
                </Grid>
                <Grid item xs={12} mt={5}>
                  {Array.from(Array(10).keys()).map(i => (
                    <Item />
                  ))}
                </Grid>
              </Grid>
            </Card>
          </RootCard>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Provider