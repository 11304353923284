import { Avatar, Grid, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { LogoMobile, Root, RootMenuIcon, headerButtonRoot, itemActive, loginButton, loginButtonClass, menuButtonRoot, menuHeader, menuHeaderMobile, rootMenuBottom, rootMenuMobile, rootMenuTop } from './index.style'
import { Button } from '@/components/Buttons/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '@/components/Logo/Logo'
import MenuIcon from '@/components/Icons/MenuIcon'
import { useAuth } from '@/provider/AuthProvider'

const Header = ({
    toggleDrawer
}) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation();
    const pathname = location.pathname

    const handleGetActive = (path) => {
        if (pathname === path) {
            return itemActive
        } else {
            return null
        }
    }
    const buttonProps = (i, addClass, isActive?: boolean) => {
        isActive = typeof isActive === 'undefined' ? true : isActive
        const getActiveClass = isActive ? handleGetActive('/' + i) : false
        return ({
            className: addClass + ' ' + getActiveClass,
            onClick: () => navigate('/' + i)
        })
    }

    const MenuTop = () => {
        return (
            <>
                <Grid item xs {...buttonProps('', headerButtonRoot)}>
                    <Button variant='text'>INICIO</Button>
                </Grid>
                <Grid item xs {...buttonProps('contact', headerButtonRoot)}>
                    <Button variant='text'>CONTACTO</Button>
                </Grid>
                <Grid item>
                    {!auth.user
                        ? <Button {...buttonProps('login', '', false)} variant='outlined' className={loginButtonClass}>Iniciar Sesión socios</Button>
                        : <LoginButton />
                    }
                </Grid>
            </>
        )
    }

    const MenuBottom = () => {
        return (
            <>
                <Grid item xs {...buttonProps('partners', menuButtonRoot)}>
                    <Button variant='text'>SOCIOS</Button>
                </Grid>
                <Grid item xs {...buttonProps('benefits', menuButtonRoot)}>
                    <Button variant='text'>BENEFICIOS</Button>
                </Grid>
                <Grid item xs {...buttonProps('provider', menuButtonRoot)}>
                    <Button variant='text'>PROVEEDORES</Button>
                </Grid>
                <Grid item xs {...buttonProps('jobs', menuButtonRoot)}>
                    <Button variant='text'>EMPLEOS</Button>
                </Grid>
                <Grid item xs {...buttonProps('courses', menuButtonRoot)} style={{ minWidth: 230 }}>
                    <Button variant='text'>CURSOS y capacitaciones</Button>
                </Grid>
                <Grid item xs {...buttonProps('study', menuButtonRoot)} style={{ minWidth: 180 }}>
                    <Button variant='text'>DONDE ESTUDIAR</Button>
                </Grid>
                <Grid item xs {...buttonProps('news', menuButtonRoot)}>
                    <Button variant='text'>novedades</Button>
                </Grid>
                <Grid item xs {...buttonProps('support', menuButtonRoot)} style={{ minWidth: 120 }}>
                    <Button variant='text'>NOS APOYAN</Button>
                </Grid>
            </>
        )
    }

    return (
        <Root>
            <Grid container>
                <Grid item xs container className={menuHeaderMobile}>
                    <Grid item xs>
                        <Link to='/'>
                            <Logo className={LogoMobile} />
                        </Link>
                    </Grid>
                    <Grid
                        className={rootMenuMobile}
                        item xs container justifyContent={'flex-end'} alignItems={'center'}>
                        <RootMenuIcon onClick={() => toggleDrawer()}>
                            <MenuIcon />
                        </RootMenuIcon>
                    </Grid>
                </Grid>
                <Grid
                    item xs={12} md container justifyContent={'flex-end'} alignItems={'center'}>
                    <MenuTop />
                </Grid>
                <Grid
                    className={rootMenuBottom + ' ' + menuHeader}
                    item xs={12} container>
                    <MenuBottom />
                </Grid>
            </Grid>
        </Root>
    )
}

const LoginButton = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate()
    const auth = useAuth()

    const logOut = () => {
        localStorage.removeItem('token')
        auth.signout(() => {
          navigate('/')
        })
        setAnchorEl(null);
      }
      
    return (
        <>
            <div onClick={(e) => handleClick(e)}>
                <Grid container spacing={3}>
                    <Grid item xs={8} container justifyContent={'flex-end'}>
                        <Typography variant='caption' color={'white'}><b>{auth.user.fullName}</b></Typography>
                        <Typography variant='caption' color={'white'}>{auth.user.email}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Avatar />
                    </Grid>
                </Grid>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
          
            >
                <MenuItem onClick={logOut}>
                    <Typography variant='body1' color="red">Cerrar sesión</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default Header