import React from 'react'
import { Root, RootBody, RootCard } from './index.style'
import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid, Typography } from '@mui/material'
import Card from '@/components/Cards/Card/Card'
import Filters from '@/modules/benefits/Filters/Filters'
import Item from '@/modules/benefits/Item/Item'

const bannerSrc = require('@/assets/images/benefits-banner-prodevent.webp')

const Benefits = () => {
  return (
    <Root>
      <HeaderTitle
        title={'BENEFICIOS'}
        src={bannerSrc}
      />
      <Grid container>
        <Grid item xs={12}>
          <RootBody>
            <Typography variant='h4' mb={4}>
              BENEFICIOS PERSONALES
            </Typography>
            <Typography variant='h5' mb={4} color="#370E74">
            </Typography>
            <Typography variant='body1' mb={4} color="#370E74">
              <b>Ayuda Profesional:</b>
            </Typography>
            <Typography variant='body1' mb={4}>
              Al ser miembro de nuestra comunidad, tendrás acceso a profesionales de diversos campos que estarán disponibles para orientarte y apoyarte. Entre ellos, contamos con abogados, contadores, psicólogos, ejecutivos de bancos,Ginmasios , seguros de viajes, entre otros
            </Typography>
            <Typography variant='body1' mb={4} color="#370E74">
              <b>Descuentos en Comercios y Servicios Asociados:</b>
            </Typography>
            <Typography variant='body1' mb={4}>
              Disfruta de una variedad de descuentos en comercios y servicios asociados para tu beneficio personal. Visita nuestro listado de asociados para más información.
            </Typography>
            <Typography variant='body1' mb={4} color="#6E6E6E">
              <b>Para obtener el descuento, debes mencionar que eres socio y presentar tu credencial de PRODEVENT CHILE.</b>
            </Typography>
          </RootBody>
        </Grid>
        <Grid item xs={12}>
          <RootCard>
            <Card paddingInterior={40}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h4'>Proveedores</Typography>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Filters />
                </Grid>
                <Grid item xs={12} mt={5}>
                  {Array.from(Array(10).keys()).map(i => (
                    <Item />
                  ))}
                </Grid>
              </Grid>
            </Card>
          </RootCard>
        </Grid>
      </Grid>
    </Root>
  )
}

export default Benefits