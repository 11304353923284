import HeaderTitle from '@/modules/global/HeaderTitle/HeaderTitle'
import { Grid } from '@mui/material'
import React from 'react'
import { PartyImage } from './index.style'
import Form from '@/modules/contact/Form/Form'

const Contact = () => {
  const bannerSrc = require('@/assets/images/contact-banner-prodevent.webp')
  const partySrc = require('@/assets/images/party-event-prodevent.webp')
  return (
    <div>
        <HeaderTitle 
          title={'contacto'}
          src={bannerSrc}
        />
        <Grid container>
          <Grid item md={6} container justifyContent={'center'} alignItems={'center'} pl={4} pr={4}>
            <Form />
          </Grid>
          <Grid item md={6}>
            <PartyImage style={{ backgroundImage: `url(${partySrc})` }}/>
          </Grid>
        </Grid>
    </div>
  )
}

export default Contact