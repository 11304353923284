import styled from '@emotion/styled';

export const Root = styled.div`
    > div {
        width: 100%;
    }
    .MuiInputLabel-root {
        margin-top: -20px;
        margin-left: -9px;
    }
`