import { maxSizeMobile } from '@/theme';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const Root = styled.div``
export const RootBody = styled.div`
    padding: 50px 80px;
    @media (max-width: ${maxSizeMobile}) {
        padding: 30px 24px;
    }
`
export const rootBg = css`
    padding: 50px 80px;
    background: hsla(209, 96%, 19%, 1);
    background: linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);

`
export const RootCard = styled.div`
    padding: 50px 80px;
    > div > div {
    width: 100%;
    }
    @media (max-width: ${maxSizeMobile}) {
        padding: 30px 24px;
    }
`