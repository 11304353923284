import styled from "@emotion/styled";

export const Root = styled.div`
     background: hsla(209, 96%, 19%, 1);
    background: linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -moz-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);
    background: -webkit-linear-gradient(45deg, hsla(209, 96%, 19%, 1) 0%, hsla(330, 100%, 51%, 1) 100%);

    padding-bottom: 80px;
    margin-top: 40px;
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
`

export const RootBottom = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    padding-top: 27px;
    padding-bottom: 36px;
    border-top: solid 1px rgba(255,255,255,0.2);
`
export const SocialImg = styled.img`
   width: 27px;
`
const chevronIcon =require('@/assets/images/icons/chevron-right.webp')
export const ListIcon = styled.ul`
    list-style-image: url(${chevronIcon});
    list-style-size: 100px;
    margin: 0px;
    padding: 0px;
    padding-left: 17px;
    li {
        margin-bottom: 8px;
    }
`
const pinIcon =require('@/assets/images/icons/pin.webp')
const envelopeIcon =require('@/assets/images/icons/envelope.webp')
export const ListIconContact = styled.ul`
    margin: 0px;
    padding: 0px;
    padding-left: 17px;
    li {
        margin-top: 8px;
    }
    li:first-child {
        list-style-image: url(${pinIcon});
    }
    li:last-child {
        list-style-image: url(${envelopeIcon});
        margin-left: 3px;
    }
`