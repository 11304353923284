import { Button } from '@/components/Buttons/Button'
import Card from '@/components/Cards/Card/Card'
import Input from '@/components/Input/Input'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Root } from './Form.style'

const Form = () => {
    return (
        <Root>
            <Card paddingInterior={'30px 19px'}>
                <Grid container spacing={3} pt={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4' >¡CONTÁCTANOS!</Typography>
                        <Typography mt={1} variant='body1' color="#777777">Envianos un mensaje rellenando el formulario</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="Nombre"
                            placeholder='Ingresa tu nombre'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="Correo"
                            placeholder='Ingresa tu correo'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="Mensaje"
                            placeholder='Ingresa tu mensaje'
                        />
                    </Grid>
                    <Grid item xs={12} container justifyContent={'center'} mt={3}>
                        <Button variant='contained' style={{ width: '100%', borderRadius: 15, height: 50 }}><b>Enviar mensaje</b></Button>
                    </Grid>
                </Grid>
            </Card>
        </Root>
    )
}

export default Form