import React from 'react'
import { ButtonPadding, ItemImage, Root, RootFilter, RootStar } from './Item.style'
import { Grid, Typography } from '@mui/material'
import { Button } from '@/components/Buttons/Button'
import { useNavigate } from 'react-router-dom'

const imageSrc = require('@/assets/images/courses-image-dj.webp')
const startSrc = require('@/assets/images/icons/star.webp')
const startHalfSrc = require('@/assets/images/icons/star-half.webp')
const startOutlineSrc = require('@/assets/images/icons/star-outline.webp')

const Item = () => {
    const navigate = useNavigate()
    return (
        <Root>
            <Grid container>
                <Grid item xs={12} position={'relative'}>
                    <ItemImage>
                        <RootFilter>
                            <Typography variant='body1' color={'white'}>
                                Curso
                            </Typography>
                        </RootFilter>
                        <img src={imageSrc} />
                    </ ItemImage>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' color="#370E74" mb={2} mt={2}><b>
                        Nombre del evento</b></Typography>
                    <RootStar>
                        <img src={startSrc} />
                        <img src={startSrc} />
                        <img src={startSrc} />
                        <img src={startHalfSrc} />
                        <img src={startOutlineSrc} />
                    </RootStar>
                    <Typography variant='body1' mb={1}><u>Descripción</u></Typography>
                    <Typography variant='body1'>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                    </Typography>
                </Grid>
                <Grid item xs={12} mt={3} container justifyContent={'center'} alignItems={'center'}>
                    <ButtonPadding>
                        <Button variant='contained' onClick={() => navigate('/courses/course')}>Ver más</Button>
                    </ButtonPadding>
                </Grid>
            </Grid>
        </Root>
    )
}

export default Item